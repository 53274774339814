import { Paper, Title, Text, Stack, Popover } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons";
import { CloudProviders, MongoDBInstance } from "realm/types";

const SizingSelectedCard = (props: {
  selectedConfig: MongoDBInstance;
  cloudProvider: CloudProviders;
}) => {
  const { selectedConfig, cloudProvider } = props;

  const shardCount = selectedConfig?.shardConfig?.shardCount || 1;

  return (
    <Paper withBorder>
      <Stack align="center" my="sm" spacing="xs">
        <Title tt="uppercase" order={3} weight={600}>
          {cloudProvider}
        </Title>
        <Title tt="capitalize" order={4} weight={400}>
          {/^R/.test(selectedConfig?.tier)
            ? selectedConfig?.tier.replace(/^R/, "M") + " Low-CPU"
            : selectedConfig?.tier}
          {/* {selectedConfig?.tier} */}
        </Title>
        <Title order={5} color="dimmed" italic>
          {shardCount === 1 ? "3 Node Replica Set" : `${shardCount} Shards`}
        </Title>
        <Text>
          {selectedConfig.pricePerHour
            ? `~$${(
                selectedConfig.pricePerHour *
                (selectedConfig?.shardConfig?.shardCount || 1)
              ).toFixed(2)} USD`
            : `N/A`}

          <Popover position="bottom" width={300} withArrow shadow="md">
            <Popover.Target>
              <Text span inline>
                <IconInfoCircle color="grey" size={16} />
              </Text>
            </Popover.Target>
            <Popover.Dropdown>
              <Text>
                This hourly rate is only for illustrative purposes. It is
                calculated with max Provisioned Throughput.
              </Text>
            </Popover.Dropdown>
          </Popover>
        </Text>
      </Stack>
    </Paper>
  );
};

export default SizingSelectedCard;
