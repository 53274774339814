import { Collapse, Grid, Group, Stack, Tabs, Text } from "@mantine/core";
import { StatComponentWorkingSet } from "features/view-sizing/stat-components";
import { useState } from "react";
import {
  CloudProviders,
  MongoDBInstance,
  SizingOutputState,
  SizingOutputType,
  SuggestedSizing,
} from "realm/types";
import {
  CloudProviderPicker,
  OutputTypePicker,
  SizingInputsTable,
  SizingSelectedCard,
  SizingCapacityTable,
  SizingSummaryTable,
  TimeStatePicker,
} from "../sizing-components";

const SummaryClusterSizing = (props: { suggestedSizing?: SuggestedSizing }) => {
  const { suggestedSizing } = props;

  const [cloudProvider, setCloudProvider] = useState<CloudProviders>("aws");
  const [timeReference, setTimeReference] =
    useState<SizingOutputState>("currentState");
  const [outputType, setOutputType] = useState<SizingOutputType>("likeForLike");
  const [sizingIndex, setSizingIndex] = useState(0);

  if (!suggestedSizing) {
    return (
      <Group position="center" my="xl">
        <Text>No Sizing Suggestions Available</Text>
      </Group>
    );
  }

  const InputPickers = () => {
    return (
      <Group position="apart" spacing="xs">
        <TimeStatePicker value={timeReference} onChange={setTimeReference} />
        <OutputTypePicker
          value={outputType}
          onChange={setOutputType}
          disabled={!suggestedSizing[timeReference]}
        />
        <CloudProviderPicker
          value={cloudProvider}
          onChange={setCloudProvider}
          disabled={!suggestedSizing[timeReference]}
        />
      </Group>
    );
  };

  if (!suggestedSizing[timeReference]) {
    const selectedInputs = suggestedSizing["currentState"].inputs;
    return (
      <>
        <InputPickers />
        <Grid my="sm" align="center">
          <Grid.Col span={3}>
            <SizingInputsTable
              selectedInputs={selectedInputs}
              timeReference={timeReference}
            />
          </Grid.Col>
          <Grid.Col span={9}>
            <Stack align="center" spacing="xs">
              <Text>Future State does not exist</Text>
              <Text>please update your inputs and click "EXECUTE"</Text>
            </Stack>
          </Grid.Col>
        </Grid>
      </>
    );
  }

  const selectedMethodology =
    suggestedSizing[timeReference][cloudProvider][outputType];

  const totalOutputs: MongoDBInstance[] = [];
  if (selectedMethodology?.replicaSet) {
    totalOutputs.push(selectedMethodology.replicaSet);
  }
  if (selectedMethodology?.sharded) {
    selectedMethodology.sharded.forEach((element) =>
      totalOutputs.push(element)
    );
  }

  const validSizingIndex = sizingIndex > totalOutputs.length;
  const selectedConfig =
    totalOutputs[validSizingIndex ? 0 : Math.max(0, sizingIndex - 1)];

  const selectedInputs = suggestedSizing[timeReference].inputs;

  const shardCount = selectedConfig?.shardConfig?.shardCount || 1;

  const selectItemsforUI = (items: MongoDBInstance[] | undefined) => {
    if (!items) {
      return [];
    }

    const OUTPUT_LENGTH = 12;
    const SHARD_FILTER_SIZE = 50 + 1;

    const filteredItems = items.filter(
      (a) =>
        a !== undefined &&
        a.shardConfig !== undefined &&
        a.shardConfig.shardCount < SHARD_FILTER_SIZE
    );
    const sortedItems = filteredItems.sort(
      (a, b) =>
        a.pricePerHour * a.shardConfig.shardCount -
        b.pricePerHour * b.shardConfig.shardCount
    );

    const itemCount = sortedItems.length;

    if (itemCount <= 23) {
      return sortedItems.slice(0, OUTPUT_LENGTH);
    }

    const step = Math.ceil(itemCount / OUTPUT_LENGTH);
    const result: MongoDBInstance[] = [];

    // Iterate through the items with the calculated step size
    for (let i = 0; i < itemCount; i += step - 1) {
      result.push(sortedItems[i]);
      if (result.length === OUTPUT_LENGTH) {
        break;
      }
    }

    return result;
  };

  return (
    <>
      <InputPickers />
      <Grid my="sm" align="flex-start">
        <Grid.Col span={3}>
          <SizingInputsTable
            selectedInputs={selectedInputs}
            timeReference={timeReference}
          />
        </Grid.Col>
        <Grid.Col span={9}>
          <Tabs
            variant="outline"
            value={validSizingIndex ? "0" : `${sizingIndex}`}
            onTabChange={(e) => setSizingIndex(Number(e))}
            mb="sm"
          >
            <Tabs.List>
              <Tabs.Tab value={"0"}>Summary</Tabs.Tab>
              {selectItemsforUI(totalOutputs).map((element, i) => {
                const shardCount = element.shardConfig?.shardCount || 1;
                return (
                  <Tabs.Tab value={(i + 1).toString()} key={i + 1}>
                    {shardCount === 1 ? "Replica Set" : `${shardCount} Shards`}
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>
          </Tabs>
          {(validSizingIndex ? 0 : sizingIndex) === 0 ? (
            <SizingSummaryTable
              instances={selectItemsforUI(totalOutputs)}
              rowClick={setSizingIndex}
            />
          ) : (
            <Grid align="flex-start">
              <Grid.Col span={3}>
                <SizingSelectedCard
                  selectedConfig={selectedConfig}
                  cloudProvider={cloudProvider}
                />
              </Grid.Col>
              <Grid.Col span={9}>
                <SizingCapacityTable
                  selectedConfig={selectedConfig}
                  selectedInputs={selectedInputs}
                />
              </Grid.Col>
            </Grid>
          )}
          <Collapse
            mt="sm"
            in={(validSizingIndex ? 0 : sizingIndex) !== 0}
            transitionDuration={500}
          >
            <StatComponentWorkingSet
              totalDataSizeMB={selectedInputs.data.totalDataSizeMB}
              totalIndexSizeMB={selectedInputs.totalIndexSizeMB}
              memSizeMB={selectedConfig?.ramGB * 1000 * shardCount}
              connections={0}
              wiredtigerCacheSizeMB={
                selectedConfig?.wiredTigerSizeMB * shardCount
              }
              disableMultiplier={true}
            />
          </Collapse>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default SummaryClusterSizing;
