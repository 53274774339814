import {
  useNavigate,
  useRouteError,
  isRouteErrorResponse,
  useParams,
} from "react-router-dom";

import {
  createStyles,
  Title,
  Text,
  Button,
  Container,
  Group,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { uploadErrorMessage } from "realm/datahooks";
import { getCurrentUser } from "realm/authentication";
import { useEffect } from "react";
import DeleteSizingModal from "features/view-sizing/components/delete-sizing-modal";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export default function ErrorPage() {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const params = useParams();

  const error = useRouteError();

  const mutation = useMutation({
    mutationFn: uploadErrorMessage,
    onError: (error) => console.log("Error: ", error),
  });
  const { mutate: mutateUploadErrorMessage } = mutation;

  useEffect(() => {
    const details: {
      severity: "Critical" | "Medium" | "Low" | "None";
      error: string;
    } = isRouteErrorResponse(error)
      ? { severity: "Low", error: "404" }
      : { severity: "Critical", error: "500" };

    mutateUploadErrorMessage({
      user: getCurrentUser()?.profile.email || "UNKNOWN USER",
      ts: new Date(),
      url: window.location.href,
      ...details,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRouteErrorResponse(error)) {
    return (
      <Container className={classes.root}>
        <div className={classes.label}>{error.status}</div>
        <Title className={classes.title}>{error.statusText}</Title>
        <Text
          color="dimmed"
          size="lg"
          align="center"
          className={classes.description}
        >
          Unfortunately, this is only a 404 page. You may have mistyped the
          address, or the page has been moved to another URL.
        </Text>
        <Group position="center">
          <Button variant="subtle" size="md" onClick={() => navigate("/view")}>
            Take me back to view sizing page
          </Button>
        </Group>
      </Container>
    );
  } else {
    return (
      <Container className={classes.root}>
        <div className={classes.label}>500</div>
        <Title className={classes.title}>Critical Error</Title>
        <Text
          color="dimmed"
          size="lg"
          align="center"
          className={classes.description}
        >
          Unfortunately, something SERIOUSLY has gone wrong. This has been
          reported to the app owner and no additional action is required on your
          part. The app owner may reach out to your at your email(if available)
          for more context of what happened.
        </Text>
        <Group position="center">
          <Button variant="subtle" size="md" onClick={() => navigate("/view")}>
            Take me back to view sizing page
          </Button>
          <DeleteSizingModal id={`${params.id}`} />
        </Group>
      </Container>
    );
  }
}
