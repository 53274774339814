import { createFormContext } from "@mantine/form";
import { Sizing } from "realm/types";
import { sizing_status } from "../components/upload-status-data";

export const initial_sizing_values: Sizing = {
  ts: new Date(),
  clusterName: "",
  opportunityName: "",
  opportunityId: "",
  accountName: "",
  accountOwner: "",
  sfid: "",
  ownerEmail: "",
  ownerFullName: "",
  schema: { t: "sizing", v: 1 },
  status: sizing_status,
  data: {} as any, // TODO: Create Type once the sizing script is completed
};

// You can give context variables any name
export const [SizingFormProvider, useSizingFormContext, useSizingForm] =
  createFormContext<Sizing>();
