import { ActionIcon, Button, createStyles, Group, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconEye, IconEyeOff, IconMessages } from "@tabler/icons";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { TalkingPoint } from "realm/types";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTalkingPoints } from "realm/datahooks";
import MarkDown from "ui/components/markdown";

const useStyles = createStyles((theme) => ({
  redRow: { color: theme.colors.red[6] },
}));

const SummaryTalkingPoints = (props: { id: string; flags: TalkingPoint[] }) => {
  const { flags } = props;
  const { classes } = useStyles();

  const [lastSavedState, setLastSavedState] = useState(
    flags.filter((obj) => obj.flag) || []
  );

  const form = useForm({
    initialValues: {
      flags: lastSavedState,
    },
  });

  const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "dismissed",
    direction: "asc",
  });

  const [records, setRecords] = useState(form.values.flags);

  useEffect(() => {
    const sortedData = sortBy(form.values.flags, sortStatus.columnAccessor);
    setRecords(
      sortStatus.direction === "desc" ? sortedData.reverse() : sortedData
    );
  }, [form.values.flags, sortStatus]);

  const setDismissed = (id: string) => {
    const index = form.values.flags.findIndex((item) => item.id === id);
    const current = form.values.flags[index].dismissed;
    form.setFieldValue(`flags.${index}.dismissed`, !current);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateTalkingPoints,
    onSuccess: () => {
      setLastSavedState(form.values.flags);
      form.resetDirty(form.values);
      form.resetTouched();
      queryClient.invalidateQueries({
        queryKey: ["sizing", props.id.toString()],
      });
    },
  });

  const {
    mutate: mutateUpdateTalkingPoints,
    isLoading: isUpdatingTalkingPoints,
  } = mutation;

  return (
    <>
      <Group position="apart">
        <Text>
          Talking Points: {form.values.flags.filter((x) => !x.dismissed).length}
        </Text>
        <Text size="sm" color="red">
          {form.isDirty() && "Unsaved Changes"}
        </Text>
      </Group>
      <DataTable
        mt="sm"
        bodyRef={bodyRef}
        withBorder
        records={records}
        minHeight={150}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        columns={[
          { accessor: "title", textAlignment: "center" },
          {
            accessor: "message",
            render: ({ message }) => {
              return <MarkDown md={message} />;
            },
          },
          {
            accessor: "dismissed",
            sortable: true,
            textAlignment: "center",
            render: (message) => (
              <Group spacing={4} position="center" noWrap>
                <ActionIcon onClick={() => setDismissed(message.id)}>
                  {message.dismissed ? (
                    <IconEyeOff size={16} />
                  ) : (
                    <IconEye size={16} />
                  )}
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        rowClassName={({ dismissed }) => (dismissed ? "" : classes.redRow)}
        noRecordsIcon={<IconMessages />}
        noRecordsText="No Topics"
      />
      {form.isDirty() && (
        <Group position="center" mt="sm">
          <Button
            variant="subtle"
            onClick={() => {
              // form.reset();
              form.setValues({ flags: lastSavedState });
              form.resetDirty({ flags: lastSavedState });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              mutateUpdateTalkingPoints({
                _id: props.id,
                payload: form.values.flags,
              });
            }}
            loading={isUpdatingTalkingPoints}
          >
            Save Changes
          </Button>
        </Group>
      )}
      {/* <pre>{JSON.stringify(form.values, null, 2)}</pre> */}
    </>
  );
};

export default SummaryTalkingPoints;
