import { Title, Grid } from "@mantine/core";
import { useEffect, useState } from "react";
import SearchSizingDataTable from "../components/search-sizing-data-table";
import { SearchSizedAccounts } from "../components/searched-sized-account";
import { SearchSizingInput } from "../types/search-types";
import SearchTermDisplay from "../components/search-term-display";
import { getCurrentUser } from "realm/authentication";

function SearchSizingPage(props: { title: string }) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const currentUser = getCurrentUser();

  const [accountSelected, setAccountSelected] = useState<SearchSizingInput>({
    type: "ownerFullName",
    value: currentUser!.profile.name!,
  });

  return (
    <>
      <Title>Search for Existing Sizing</Title>
      <SearchSizedAccounts {...{ accountSelected, setAccountSelected }} />
      <SearchTermDisplay accountSelected={accountSelected} />
      <Grid mt="md">
        <Grid.Col>
          <SearchSizingDataTable accountSelected={accountSelected} />
        </Grid.Col>
      </Grid>
    </>
  );
}

export default SearchSizingPage;
