import { DataTable } from "mantine-datatable";
import { SizingData } from "realm/types";
import formatNumber from "utils/format-number";

const StatComponentUsecaseIndicators = (props: { data: SizingData }) => {
  const { data } = props;
  const { serverInfo } = data;
  const { metrics } = serverInfo;

  const elements = [
    {
      label: `$text`,
      value: metrics.text,
    },
    {
      label: `$regex`,
      value: metrics.regex,
    },
    {
      label: `$lookup`,
      value: 0, // Not Yet Implemented
    },
  ].sort((a, b) => b.value - a.value);

  return (
    <DataTable
      withBorder
      striped
      columns={[
        { accessor: "label", title: "Anti-Patterns" },
        {
          accessor: "value",
          title: "",
          textAlignment: "right",
          render: ({ value }) => {
            return formatNumber(value, 2);
          },
        },
      ]}
      records={elements}
      idAccessor="label"
    />
  );
};

export default StatComponentUsecaseIndicators;
