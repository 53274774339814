import { Accordion, Code, List, Paper, Text } from "@mantine/core";
import { useState } from "react";
import { useRealmAuth } from "realm/auth.provider";

const SizingInstructions = () => {
  const [value, setValue] = useState<string | null>(null);
  const { currentUser } = useRealmAuth();

  return (
    <Accordion
      value={value}
      onChange={setValue}
      styles={{
        control: {
          "&:hover": { backgroundColor: "inherit" },
          paddingTop: 2,
          paddingBottom: 2,
        },
        // label: { textAlign: "end" },
        item: { borderBottom: 0 },
      }}
      chevronPosition="left"
    >
      <Accordion.Item value="customer">
        <Accordion.Control>
          {value === "customer" ? "Hide" : "Show"} Customer Instructions
        </Accordion.Control>
        <Accordion.Panel>
          <Paper withBorder p="sm" radius="sm">
            <List type="ordered">
              <List.Item>
                Download{" "}
                <Text
                  component="a"
                  href="https://gist.github.com/TravWill-Mongo/32f9738b9a6768e634126a9616ae38d1"
                  target="_blank"
                  c="blue"
                  inherit
                >
                  getMongoSizingData.js
                </Text>
              </List.Item>
              <List.Item>
                <Text>Run Command</Text>
                <Code mb="xs">
                  {
                    "mongosh <<CONNECTION_STRING>> getMongoSizingData.js --norc --quiet > <<CLUSTER_NAME>>.json"
                  }
                </Code>
              </List.Item>
              <List.Item>
                Send Output to:{" "}
                <Text
                  component="a"
                  href={`mailto://${currentUser?.profile.email}`}
                  target="_blank"
                  c="blue"
                  inherit
                >
                  {currentUser?.profile.email}
                </Text>
              </List.Item>
            </List>
          </Paper>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="internal">
        <Accordion.Control>
          {value === "internal" ? "Hide" : "Show"} Internal Instructions
        </Accordion.Control>
        <Accordion.Panel>
          <Paper withBorder p="sm" radius="sm">
            <List type="ordered">
              <List.Item>Retrieve Sizing Output from Customer</List.Item>
              <List.Item>Find Salesforce Account & Opportunity</List.Item>
              <List.Item>Select Existing or Create New Cluster</List.Item>
              <List.Item>Upload One Sizing File</List.Item>
            </List>
          </Paper>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default SizingInstructions;
