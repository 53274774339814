import { Text } from "@mantine/core";

export const VersionInfo = () => {
  const version = process.env.REACT_APP_BUILD_VERSION;
  const date = process.env.REACT_APP_BUILD_DATE;

  const env = process.env.REACT_APP_ENVIRONMENT;
  const appId = process.env.REACT_APP_REALM_APP_ID;
  const url = process.env.REACT_APP_URL;

  if (!version) {
    console.info("No version information present at build time.");
    return <></>;
  }

  return (
    <Text size="sm" color="dimmed">
      App:{" "}
      <Text
        td="underline"
        c="blue"
        component="a"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {appId}
      </Text>
      , Env: {env}, Build: {version} ({date})
    </Text>
  );
};
