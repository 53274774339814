import { useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { Title, Text, Card } from "@mantine/core";
import { getCurrentUser } from "realm/authentication";

const UsageDashboard = (props: { title: string }) => {
  const base_url = "https://charts.mongodb.com/charts-_shared-demo-hazfi";
  const dashboard_id = "f291eb7e-52f2-4c82-a60c-038ccfca538f";

  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const currentUser = getCurrentUser()!;
  async function getValidAccessToken() {
    await currentUser.refreshAccessToken();
  }

  getValidAccessToken();

  useEffect(() => {
    const client = currentUser.accessToken;

    const sdk = new ChartsEmbedSDK({
      baseUrl: base_url,
      getUserToken: () => client!,
    });

    const dashboard = sdk.createDashboard({
      dashboardId: dashboard_id,
      widthMode: "scale",
    });

    dashboard.render(document.getElementById("dashboard")!);
  }, [currentUser.accessToken]);

  return (
    <>
      <Title>Usage Dashboard</Title>
      <Text>Powered by MongoDB Charts</Text>
      <Card withBorder p={0} mt="sm" radius="lg">
        <div id="dashboard" style={{ height: "75vh" }} />
      </Card>
    </>
  );
};

export default UsageDashboard;
