import { Text } from "@mantine/core";
import { SearchSizingInput } from "../types/search-types";

const SearchTermDisplay = (props: { accountSelected: SearchSizingInput }) => {
  const { accountSelected } = props;

  let searchTerm = "";
  switch (accountSelected?.type) {
    case "accountName":
      searchTerm = `SalesForce ID: ${accountSelected.value}`;
      break;
    case "accountOwner":
      searchTerm = `Account Executive: ${accountSelected.value}`;
      break;
    case "ownerFullName":
    default:
      searchTerm = `Solutions Architect: ${accountSelected.value}`;
      break;
  }

  return (
    <Text mt="md">
      Search Terms:
      <Text fw={700} inline>
        {searchTerm}
      </Text>
    </Text>
  );
};

export default SearchTermDisplay;
