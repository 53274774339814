import { DataTable } from "mantine-datatable";
import { SizingData } from "realm/types";
import formatNumber from "utils/format-number";

const StatComponentOpMetrics = (props: { data: SizingData }) => {
  const { data } = props;
  const { serverInfo } = data;
  const { metrics } = serverInfo;
  const { opcounters } = metrics;

  const elements = [
    {
      label: `Queries`,
      value: opcounters.query,
    },
    {
      label: `Inserts`,
      value: opcounters.insert,
    },
    {
      label: `Updates`,
      value: opcounters.update,
    },
    {
      label: `Deletes`,
      value: opcounters.delete,
    },
    {
      label: `GetMores`,
      value: opcounters.getmore,
    },
    {
      label: `Commands`,
      value: opcounters.command,
    },
  ].sort((a, b) => b.value - a.value);

  return (
    <DataTable
      withBorder
      striped
      columns={[
        { accessor: "label", title: "Operations" },
        {
          accessor: "value",
          title: "",
          textAlignment: "right",
          render: ({ value }) => {
            return formatNumber(value, 2);
          },
        },
      ]}
      records={elements}
      idAccessor="label"
    />
  );
};

export default StatComponentOpMetrics;
