import React, { useMemo, useState } from "react";
import {
  getCurrentUser,
  hasLoggedInUser,
  logoutCurrentUser,
  loginGoogle,
} from "realm/authentication";

const INITIAL_VALUE = {
  isLoggedIn: hasLoggedInUser(),
  currentUser: getCurrentUser(),
  actions: {
    handleLogin: async () => {},
    handleLogout: async () => {},
  },
};

const RealmAuthContext = React.createContext(INITIAL_VALUE);

export const useRealmAuth = () => {
  const context = React.useContext(RealmAuthContext);
  if (!context) {
    throw new Error("useRealmAuth must be used within a RealmAuthProvider");
  }
  return context;
};

export const RealmAuthProvider = ({ children }: { children: JSX.Element }) => {
  const [auth, setAuth] = useState(INITIAL_VALUE);

  const handleLogin = async () => {
    await loginGoogle().then(() => {
      setAuth({
        ...auth,
        isLoggedIn: hasLoggedInUser(),
        currentUser: getCurrentUser(),
      });
    });
  };

  const handleLogout = async () => {
    if (auth.isLoggedIn) {
      await logoutCurrentUser();
      setAuth({
        ...auth,
        isLoggedIn: false,
        currentUser: null,
      });
    }
  };

  const authInfo = useMemo(() => {
    return { isLoggedIn: auth.isLoggedIn, currentUser: auth.currentUser };
  }, [auth]);

  return (
    <RealmAuthContext.Provider
      value={{
        ...authInfo,
        actions: { handleLogin, handleLogout },
      }}
    >
      {children}
    </RealmAuthContext.Provider>
  );
};
