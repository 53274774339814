import { Text, Progress } from "@mantine/core";
import { formatBytes } from "utils/format-bytes";
import formatNumber from "utils/format-number";

type StatsProp = {
  data: StatData[];
  fillPercent: number;
  limit?: number;
  unit: "byte" | "number";
};

type StatData = {
  label: string;
  value: number;
};

const StatItem = (props: {
  label: string;
  value: number;
  denominator: number;
  fillPercent: number;
  unit: "byte" | "number";
  color: string;
}) => {
  const { label, value, denominator, fillPercent, unit, color } = props;
  return (
    <>
      <Text size="sm" truncate>
        {label}
      </Text>
      <Progress
        size="xl"
        sections={[
          {
            value: (value / denominator) * fillPercent,
            color: color,
            label:
              unit === "byte" ? formatBytes(value) : formatNumber(value, 2),
            tooltip:
              unit === "byte" ? formatBytes(value) : formatNumber(value, 2),
          },
        ]}
      />
    </>
  );
};

const ProgressColors = ["teal.6", "cyan.6", "blue.6", "indigo.6", "violet.6"];

const StatsProgressCompare = (props: StatsProp) => {
  const { data, fillPercent, limit, unit } = props;

  const metricSort = data
    .sort((a, b) => b.value - a.value)
    .slice(0, limit || 10);

  return (
    <>
      {metricSort.map((stat, i) => {
        return (
          <StatItem
            key={`${stat.label}-${stat.value}-${i}`}
            label={stat.label}
            value={stat.value}
            denominator={metricSort[0].value < 1 ? 1 : metricSort[0].value}
            fillPercent={fillPercent}
            unit={unit}
            color={ProgressColors[i]}
          />
        );
      })}
    </>
  );
};

export default StatsProgressCompare;
