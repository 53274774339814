import { createStyles, Text, Group, Stack } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { DataTable } from "mantine-datatable";
import { useState } from "react";
import { getCurrentUser } from "realm/authentication";
import SizedClusterDataTable from "./sized-cluster-data-table";
import { SearchSizingInput } from "../types/search-types";
import { getSizingsBySearchTerms } from "../data";

const useStyles = createStyles(() => ({
  chevron: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: `transform 200ms ease`,
    width: 24,
    minWidth: 24,

    "&[data-rotate]": {
      transform: "rotate(180deg)",
    },
  },
}));

const SearchSizingDataTable = (props: {
  accountSelected: SearchSizingInput | undefined;
}) => {
  const { accountSelected } = props;

  const { classes } = useStyles();
  const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);

  const { data, isFetching } = useQuery({
    queryKey: ["allSizings", accountSelected],
    queryFn: () =>
      getSizingsBySearchTerms({
        type: accountSelected?.type || "ownerFullName",
        value: accountSelected?.value || getCurrentUser()!.profile.name!,
      }),
    onSuccess: (data) => {
      data.length > 0 && setExpandedRecordIds([data[0]._id]);
    },
    keepPreviousData: true,
  });

  return (
    <DataTable
      withBorder
      borderRadius="sm"
      minHeight={70}
      emptyState={<div>No Records Found</div>}
      fetching={isFetching}
      columns={[
        {
          accessor: "_id",
          title: "Account",
          render: ({ _id, accountOwner }) => (
            <>
              <Group>
                <IconChevronDown
                  className={classes.chevron}
                  data-rotate={_id === expandedRecordIds[0] ? true : undefined}
                />
                <Stack spacing={0}>
                  <Text fz="lg">{_id}</Text>
                  <Text fz="sm" color={"dimmed"}>
                    {accountOwner}
                  </Text>
                </Stack>
              </Group>
            </>
          ),
        },
        {
          accessor: "count",
          title: "Sizings",
          width: 74,
          textAlignment: "center",
        },
        {
          accessor: "ts",
          title: "Latest",
          render: ({ latest }) => latest.toLocaleDateString(),
          width: 100,
          textAlignment: "center",
        },
      ]}
      records={data || []}
      idAccessor="_id"
      rowExpansion={{
        expanded: {
          recordIds: expandedRecordIds,
          onRecordIdsChange: setExpandedRecordIds,
        },
        content: ({ record }) => (
          <SizedClusterDataTable records={record.meta} />
        ),
      }}
    />
  );
};

export default SearchSizingDataTable;
