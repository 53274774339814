import { Group, Popover, Stack, Text } from "@mantine/core";
import { IconFlag, TablerIcon } from "@tabler/icons";
import { DataTable } from "mantine-datatable";
import React from "react";
import MarkDown from "ui/components/markdown";

type StatTableElementProps = {
  icon?: TablerIcon;
  name: string;
  value: string | number | JSX.Element;
  error?: boolean;
  errorTitle?: string;
  errorMessage?: string | string[];
};

const StatsDataTable = (props: {
  title: string;
  records: StatTableElementProps[];
}) => {
  return (
    <DataTable
      striped
      withBorder
      highlightOnHover
      idAccessor="name"
      columns={[
        {
          title: props.title,
          accessor: "name",
          textAlignment: "left",
          render: ({ icon, name, error }) => {
            return (
              <Group position="left" spacing={10}>
                {icon !== undefined &&
                  React.createElement(icon, {
                    size: 18,
                    color: error ? "#FA5639" : "grey",
                  })}
                <Text>{name}</Text>
              </Group>
            );
          },
        },
        {
          title: "",
          accessor: "value",
          textAlignment: "right",
          render: ({ value, error, errorTitle, errorMessage }) => {
            return (
              <Group position="right" spacing={4}>
                <Text fw={700} tt="capitalize">
                  {value}
                </Text>
                {error && (
                  <Popover
                    position="bottom"
                    withArrow
                    shadow="md"
                    width={400}
                    withinPortal
                  >
                    <Popover.Target>
                      <div>
                        <IconFlag size={16} />
                      </div>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack align="start" spacing="sm">
                        <Text fw="700">{errorTitle}</Text>
                        {typeof errorMessage === "string" ? (
                          <MarkDown md={errorMessage} />
                        ) : (
                          errorMessage?.map((message, i) => (
                            <Text key={i}>{message}</Text>
                          ))
                        )}
                      </Stack>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </Group>
            );
          },
        },
      ]}
      records={props.records}
      rowStyle={({ error }) => (error ? { color: "#FA5639" } : undefined)}
    />
  );
};

export { StatsDataTable };
export type { StatTableElementProps };
