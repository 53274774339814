import {
  Button,
  Center,
  Group,
  HoverCard,
  NumberInput,
  Stack,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconInfoCircle } from "@tabler/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { generateSizing } from "realm/datahooks";
import { GenerateSizingInput, SuggestedSizingInput } from "realm/types";
import { formatBytes } from "utils/format-bytes";

const ToolTipValue = (props: {
  label: string;
  tooltip: string | JSX.Element;
}) => (
  <Group position="left" spacing={2}>
    <HoverCard position="left" width={300} withArrow shadow="md" withinPortal>
      <HoverCard.Target>
        <Center>
          <IconInfoCircle color="grey" size={14} />
        </Center>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="xs" align="center">
          {props.tooltip}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
    <Text size="xs">{props.label}</Text>
  </Group>
);

const SizingInputsTable = (props: {
  selectedInputs: SuggestedSizingInput;
  timeReference: "currentState" | "futureState";
}) => {
  const { selectedInputs, timeReference } = props;
  const isFutureState = timeReference === "futureState";

  const [editMode, setEditMode] = useState(false);
  const params = useParams();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: generateSizing,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["sizing", params.id],
      });
      setLastSavedState(form.values);
      form.resetDirty(form.values);
      form.resetTouched();
      setEditMode(false);
    },
    onError: (error) => console.log("Error: ", error),
  });
  const { mutate: mutateGenerateSizing, isLoading } = mutation;

  const generateInput = (): GenerateSizingInput => {
    const variables = selectedInputs.bestPracticeValues;
    return {
      _id: params.id!,
      numCores: selectedInputs.numCores,
      wiredtigerCacheSize: selectedInputs.wiredTigerCache?.cacheSizeMB || 0,
      totalDataSize: selectedInputs.data.totalDataSizeMB / 1000 || 0,
      totalStorageSize: selectedInputs.disk.diskUtilizationMB || 0,
      totalIndexSize: selectedInputs.totalIndexSizeMB / 1000 || 0,
      replicationInfo: {
        logSizeMB: selectedInputs.oplog?.logSizeGB || 0,
        usedMB: 0,
        timeDiffHours: 0,
      },
      configuration: selectedInputs.configuration as
        | "SingleNode"
        | "ReplicaSet"
        | "Sharded",
      sizingTime: "futureState",
      memSizeMB: selectedInputs.memSizeMB,
      bestPracticeValues: {
        diskCapacity: variables.diskCapacity * 100,
        oplogSize: variables.oplogSize * 100,
        documentsInCache: variables.documentsInCache * 100,
        percentIndexInWiredTiger: variables.percentIndexInWiredTiger * 100,
        shardingStorageMB: variables.shardingStorageMB,
        shardingIndexMB: variables.shardingIndexMB,
        workingSetMB: variables.workingSetMB,
      },
    };
  };

  const compressionFactor =
    selectedInputs.data.totalDataSizeMB /
    selectedInputs.data.totalStorageSizeMB;

  const [lastSavedState, setLastSavedState] = useState<GenerateSizingInput>(
    generateInput()
  );

  const form = useForm({
    initialValues: lastSavedState,

    validate: {
      totalDataSize: (value) =>
        value > 0 ? null : "Data Size must be greater than 0",
      totalIndexSize: (value) =>
        value > 0 ? null : "Index Size must be greater than 0",
    },

    transformValues: (values) => ({
      totalDataSize: values.totalDataSize * 1000 ** 3,
      totalIndexSize: values.totalIndexSize * 1000 ** 3,
      totalStorageSize: (values.totalDataSize * 1000 ** 3) / compressionFactor,
      wiredtigerCacheSize: values.wiredtigerCacheSize * 1000 ** 2,
      replicationInfo: {
        logSizeMB: values.replicationInfo?.logSizeMB * 1000 || 0,
        usedMB: values.replicationInfo.usedMB,
        timeDiffHours: values.replicationInfo.timeDiffHours,
      },
      bestPracticeValues: {
        diskCapacity: values.bestPracticeValues.diskCapacity / 100,
        oplogSize: values.bestPracticeValues.oplogSize / 100,
        documentsInCache: values.bestPracticeValues.documentsInCache / 100,
        percentIndexInWiredTiger:
          values.bestPracticeValues.percentIndexInWiredTiger / 100,
        shardingStorageMB: 1000000,
        shardingIndexMB: 65000,
        workingSetMB: 65000,
      },
    }),
  });

  useEffect(() => {
    form.setValues(generateInput());
    setLastSavedState(generateInput());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInputs]);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        mutateGenerateSizing({
          ...form.values,
          ...values,
        });
        // console.log({
        //   ...form.values,
        //   ...values,
        // });
        // setEditMode(false);
      })}
    >
      <Stack spacing="sm">
        <DataTable
          withBorder
          striped
          columns={[
            {
              accessor: "label",
              title: "Inputs",
              render: ({ label, tooltip }) => {
                return <ToolTipValue label={label} tooltip={tooltip} />;
              },
            },
            {
              accessor: "value",
              title: "",
              textAlignment: "right",

              render: ({ value, editable }) => {
                return isFutureState && editable && editMode ? (
                  <Group position="right" spacing={2}>
                    <NumberInput
                      min={0}
                      step={10}
                      stepHoldDelay={500}
                      stepHoldInterval={100}
                      w={90}
                      sx={{
                        root: {
                          alignContent: "right",
                        },
                        input: {
                          textAlign: "right",
                        },
                      }}
                      {...form.getInputProps(editable)}
                    />
                    {/* <Text>GB</Text> */}
                  </Group>
                ) : (
                  formatBytes(value * 1000 ** 2)
                );
              },
            },
          ]}
          records={[
            {
              label: `Data Size`,
              value: form.values.totalDataSize * 1000,
              editable: "totalDataSize",
              tooltip:
                "Total Uncompressed Data Size. This value will be divided by the compression factor below to determine the size after compression. This compressed size will be added to the overall storage size.",
            },

            {
              label: `Index Size`,
              value: form.values.totalIndexSize * 1000,
              editable: "totalIndexSize",
              tooltip:
                "Total size of all indexes on the cluster. This size will be added to the overall storage size.",
            },
            {
              label: `Oplog Size`,
              value: form.values.replicationInfo?.logSizeMB * 1000 || 0,
              editable: "replicationInfo.logSizeMB",
              tooltip: (
                <Text>
                  The disk space available for the Operation Log. Suggested to
                  size appropriately for node recovery time and/or changestream
                  resumability. This size will be added to the overall storage
                  size.
                </Text>
              ),
            },
            {
              label: `Disk Usage`,
              value:
                (form.values.totalDataSize * 1000) / compressionFactor +
                  form.values.totalIndexSize * 1000 +
                  form.values.replicationInfo?.logSizeMB * 1000 || 0,
              tooltip: (
                <Text>
                  Sum of the{" "}
                  <Text span fw={700}>
                    compressed data
                  </Text>
                  ,{" "}
                  <Text span fw={700}>
                    indexes
                  </Text>
                  , and{" "}
                  <Text span fw={700}>
                    oplog
                  </Text>{" "}
                  sizes. The recommended configuration will ensure data fits
                  within the 'Max Disk Util' percentage.
                </Text>
              ),
            },

            // {
            //   label: `WT Cache`,
            //   value: form.values.wiredtigerCacheSize,
            // },
          ]}
          idAccessor="label"
        />
        <DataTable
          withBorder
          // withColumnBorders
          striped
          columns={[
            {
              accessor: "label",
              title: "Variables",
              render: ({ label, tooltip }) => {
                return <ToolTipValue label={label} tooltip={tooltip} />;
              },
            },
            {
              accessor: "value",
              title: "",
              textAlignment: "right",
              render: ({ value, editable }) => {
                return isFutureState && editable && editMode ? (
                  <Group position="right" spacing={2}>
                    <NumberInput
                      min={0}
                      max={100}
                      step={1}
                      stepHoldDelay={500}
                      stepHoldInterval={100}
                      w={65}
                      sx={{
                        root: {
                          alignContent: "right",
                        },
                        input: {
                          textAlign: "right",
                        },
                      }}
                      {...form.getInputProps(editable)}
                    />
                  </Group>
                ) : (
                  value
                );
              },
            },
          ]}
          records={[
            {
              label: `Disk Util  %`,
              value: form.values.bestPracticeValues.diskCapacity || 0,
              editable: "bestPracticeValues.diskCapacity",
              tooltip:
                "The upper threshold for amount of disk utilization allowed before requiring more disk capacity.",
            },
            {
              label: `Data in Cache %`,
              value: form.values.bestPracticeValues.documentsInCache || 0,
              editable: "bestPracticeValues.documentsInCache",
              tooltip:
                "The percentage of data that is needed in the cache for best read performance.",
            },
            // { // Excluding temporarily until we decide how we want to size oplog.
            //   label: `Oplog to Data`,
            //   value: form.values.bestPracticeValues.oplogSize || 0,
            //   editable: "bestPracticeValues.oplogSize",
            //   tooltip: "The percentage of oplog compared to overall data size.",
            // },
            {
              label: `Index in WT %`,
              value:
                form.values.bestPracticeValues.percentIndexInWiredTiger || 0,
              editable: "bestPracticeValues.percentIndexInWiredTiger",
              tooltip:
                "The amount of indexes required in WT Cache for application specific performance.",
            },
            {
              label: `Compression`,
              value: compressionFactor.toFixed(2),
              tooltip:
                "The amount of compression achieved with the existing block compressor.",
            },
            {
              label: `Index to Data %`,
              value: (
                (form.values.totalIndexSize / form.values.totalDataSize) *
                100
              ).toFixed(0),
              tooltip: "The percentage of index compared to overall data size.",
            },
          ]}
          idAccessor="label"
        />

        {isFutureState &&
          (editMode ? (
            <Group position="center">
              <Button
                variant="subtle"
                onClick={() => {
                  form.setValues(lastSavedState);
                  form.resetDirty(lastSavedState);
                  setEditMode(false);
                }}
                loading={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!form.isValid()}
                loading={isLoading}
              >
                Run
              </Button>
            </Group>
          ) : (
            <Button variant="subtle" onClick={() => setEditMode(!editMode)}>
              Edit Inputs
            </Button>
          ))}
      </Stack>
    </form>
  );
};

export default SizingInputsTable;
