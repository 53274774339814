import { createStyles, Group, Progress, Stack, Text } from "@mantine/core";
import { SizingData } from "realm/types";
import formatHumanizeSeconds from "utils/format-duration";
import StatComponentDocumentMetrics from "./document-metrics";
import StatComponentOpMetrics from "./op-metrics";
import StatComponentUsecaseIndicators from "./search-indicators";

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 12,
  },
}));

const StatComponentTopOperations = (props: { data: SizingData }) => {
  const { data } = props;

  const { opcounters } = data.serverInfo.metrics;

  const allOperations = [
    { type: "Commands", value: opcounters.command },
    { type: "Queries", value: opcounters.query },
    { type: "Inserts", value: opcounters.insert },
    { type: "Deletes", value: opcounters.delete },
    { type: "Updates", value: opcounters.update },
    { type: "GetMores", value: opcounters.getmore },
  ];

  const ProgressColors = [
    "teal.6",
    "cyan.6",
    "blue.6",
    "indigo.6",
    "violet.6",
    "grape.6",
  ];

  const totalOperationCount = allOperations.reduce((a, b) => a + b.value, 0);

  const { classes } = useStyles();

  return (
    <Stack align="center" spacing="xs">
      <Text>
        <Text span fw={700}>
          Since Last Restart:{" "}
        </Text>
        {/* {formatDate(new Date(), "MMM do, yyyy")}{" "} */}
        {formatHumanizeSeconds(data.serverInfo.uptimeSec)} Uptime
        {/* <Text span color="dimmed" size="xs">
          TODO: The below line needs to be a specific date in time, not number of seconds ago
          {formatHumanizeSeconds(data.serverInfo.uptimeSec)} ago
        </Text> */}
      </Text>
      {/* Total Operations: {totalOperationCount} */}
      <Progress
        classNames={{ label: classes.label }}
        w={565}
        size={30}
        sections={allOperations
          .sort((a, b) => b.value - a.value)
          .map((op, i) => {
            const value = (op.value / totalOperationCount) * 100;
            return {
              value: value,
              label: op.type,
              color: ProgressColors[i],
              tooltip: `${value.toFixed(2)}%`,
            };
          })}
      />
      <Group position="center" align="stretch">
        <StatComponentOpMetrics data={data} />
        <StatComponentDocumentMetrics data={data} />
        <StatComponentUsecaseIndicators data={data} />
      </Group>
    </Stack>
  );
};

export default StatComponentTopOperations;
