import { Box, createStyles, Group, Text, Tooltip } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { sortBy } from "lodash";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { SizingCollectionStats } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import formatNumber from "utils/format-number";
import IndexStatsTable from "./index-stats-table";

const PAGE_SIZE = 10;

const useStyles = createStyles((theme) => ({
  chevron: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: `transform 200ms ease`,
    width: 16,
    minWidth: 16,

    "&[data-rotate]": {
      transform: "rotate(180deg)",
    },
  },

  details: {
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  blueishRow: { color: theme.colors.blue[6] },
}));

const DatabaseDetailsTable = (props: {
  collections: SizingCollectionStats[];
}) => {
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "dataSize",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(props.collections.slice(0, PAGE_SIZE));

  useEffect(() => {
    const data = sortBy(props.collections, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const PAGE_FROM = (page - 1) * PAGE_SIZE;
  const PAGE_TO = PAGE_FROM + PAGE_SIZE;

  const { classes } = useStyles();
  const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);
  return (
    <DataTable
      ml="md"
      // my="xs"
      withBorder
      recordsPerPage={PAGE_SIZE}
      page={page}
      onPageChange={(p) => setPage(p)}
      records={records.slice(PAGE_FROM, PAGE_TO)}
      totalRecords={props.collections.length}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      columns={[
        {
          accessor: "collectionName",
          title: "Collection",
          sortable: true,
          render: ({ collectionName }) => (
            <Group>
              <IconChevronDown
                className={classes.chevron}
                data-rotate={
                  expandedRecordIds.includes(collectionName.toLocaleString())
                    ? true
                    : undefined
                }
              />
              <Tooltip label={collectionName.toLocaleString()}>
                <Text truncate w={90}>
                  {collectionName.toLocaleString()}
                </Text>
              </Tooltip>
            </Group>
          ),
        },
        {
          accessor: "count",
          title: "Documents",
          sortable: true,
          textAlignment: "center",
          render: ({ count }) => <>{formatNumber(count, 1)}</>,
        },
        {
          accessor: "dataSize",
          textAlignment: "center",
          sortable: true,
          render: ({ dataSize }) => <>{formatBytes(dataSize)}</>,
        },
        {
          accessor: "storageSize",
          textAlignment: "center",
          sortable: true,
          render: ({ storageSize }) => <>{formatBytes(storageSize)}</>,
        },
        {
          accessor: "nindexes",
          title: "Indexes",
          sortable: true,
          textAlignment: "center",
        },
        {
          accessor: "totalIndexSize",
          title: "Index Size",
          sortable: true,
          textAlignment: "center",
          render: ({ totalIndexSize }) => <>{formatBytes(totalIndexSize)}</>,
        },
        {
          accessor: "Capped",

          textAlignment: "center",
          render: ({ capped }) => <>{capped ? "True" : "False"}</>,
        },
        {
          accessor: "parsedCreationString",
          title: "Compressor",
          textAlignment: "center",
          render: ({ parsedCreationString }) => (
            <Text tt="capitalize">
              {parsedCreationString[0].block_compressor}
            </Text>
          ),
        },
      ]}
      idAccessor="collectionName"
      rowExpansion={{
        allowMultiple: true,
        expanded: {
          recordIds: expandedRecordIds,
          onRecordIdsChange: setExpandedRecordIds,
        },

        content: ({ record }) => (
          <Box className={classes.details}>
            <IndexStatsTable data={record} />{" "}
          </Box>
        ),
      }}
      rowClassName={({ collectionName }) =>
        expandedRecordIds.includes(collectionName.toLocaleString())
          ? classes.blueishRow
          : undefined
      }
    />
  );
};

export default DatabaseDetailsTable;
