import { SegmentedControl } from "@mantine/core";
import { SizingOutputState } from "realm/types";

const TimeStatePicker = (props: {
  value: SizingOutputState;
  onChange: React.Dispatch<React.SetStateAction<SizingOutputState>>;
}) => {
  return (
    <SegmentedControl
      value={props.value}
      onChange={(e: SizingOutputState) => props.onChange(e)}
      data={[
        {
          value: "currentState",
          label: "Current",
        },
        {
          value: "futureState",
          label: "Future",
          // disabled: true,
        },
      ]}
    />
  );
};

export default TimeStatePicker;
