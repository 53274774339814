import { SegmentedControl, Text } from "@mantine/core";
import React from "react";
import { SizingOutputType } from "realm/types";

const OutputTypePicker = (props: {
  value: SizingOutputType;
  onChange: React.Dispatch<React.SetStateAction<SizingOutputType>>;
  disabled?: boolean;
}) => {
  return (
    <SegmentedControl
      value={props.value}
      onChange={(e: SizingOutputType) => props.onChange(e)}
      disabled={props.disabled}
      data={[
        {
          value: "likeForLike",
          label: <Text color="red">Similar Hardware</Text>,
        },
        {
          value: "bestPractice",
          label: <Text>Custom Spec</Text>,
        },
      ]}
    />
  );
};

export default React.memo(OutputTypePicker);
