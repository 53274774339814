import {
  Text,
  Group,
  Popover,
  Paper,
  Stack,
  createStyles,
  Space,
} from "@mantine/core";
import { IconFlag, IconInfoCircle } from "@tabler/icons";
import { ReactNode } from "react";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  tooltips: {
    position: "absolute",
    bottom: theme.spacing.xs - 6,
    right: theme.spacing.xs + 2,
    // pointerEvents: "none",
  },
}));

type StatsCardProps = {
  title: string;
  children: ReactNode;
  flaggedTooltip?: string;
  infoTooltip?: string;
  infoTooltipNode?: JSX.Element;
};

export function StatsCard(props: StatsCardProps) {
  const { classes } = useStyles();

  return (
    <Paper withBorder shadow={"sm"} radius="md" p="md" className={classes.card}>
      <Stack justify="space-between">
        <div>
          <Text size="xs" transform="uppercase" weight={700} color="dimmed">
            {props.title}
          </Text>
          <Text size="lg" weight={500}></Text>
          {props.children}
        </div>
        {(props.flaggedTooltip || props.infoTooltipNode) && (
          <>
            <Space h="xs" />
            <Group position="right" spacing="sm" className={classes.tooltips}>
              {props.flaggedTooltip && (
                <Popover position="bottom" width={200} withArrow shadow="md">
                  <Popover.Target>
                    <Text color="red" size="xs">
                      <IconFlag size={16} />
                    </Text>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="sm">{props.flaggedTooltip}</Text>
                  </Popover.Dropdown>
                </Popover>
              )}
              {props.infoTooltipNode && (
                <Popover position="bottom" width={400} withArrow shadow="md">
                  <Popover.Target>
                    <Text size="xs">
                      <IconInfoCircle color="grey" size={16} />
                    </Text>
                  </Popover.Target>
                  <Popover.Dropdown>{props.infoTooltipNode}</Popover.Dropdown>
                </Popover>
              )}
            </Group>
          </>
        )}
      </Stack>
    </Paper>
  );
}
