import { Center, SegmentedControl, Image, Box } from "@mantine/core";
import { CloudProviders } from "realm/types";

const CloudProviderPicker = (props: {
  value: CloudProviders;
  onChange: React.Dispatch<React.SetStateAction<CloudProviders>>;
  disabled?: boolean;
}) => {
  return (
    <SegmentedControl
      value={props.value}
      onChange={(e: CloudProviders) => props.onChange(e)}
      disabled={props.disabled}
      data={[
        {
          value: "aws",
          label: (
            <Center>
              <Image src="/aws-icon-logo.png" height={16} fit="contain" />
              <Box ml={10}>AWS</Box>
            </Center>
          ),
        },
        {
          value: "gcp",
          label: (
            <Center>
              <Image src="/gcp-icon-logo.png" height={16} fit="contain" />
              <Box ml={10}>GCP</Box>
            </Center>
          ),
        },
        {
          value: "azure",
          label: (
            <Center>
              <Image src="/azure-icon-logo.png" height={16} fit="contain" />
              <Box ml={10}>Azure</Box>
            </Center>
          ),
        },
      ]}
    />
  );
};

export default CloudProviderPicker;
