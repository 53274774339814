import { sortBy } from "lodash";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { topIndexes } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import {
  Box,
  Group,
  LoadingOverlay,
  RingProgress,
  Stack,
  Text,
} from "@mantine/core";
import StatsProgressCompare from "ui/components/stats-bar-charts";
import formatNumber from "utils/format-number";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getTopIndexes } from "realm/datahooks";

const PAGE_SIZE = 5;
const QUERY_LIMIT = 100;

const StatComponentTopIndexes = (props: {
  nIndexes: number;
  totalIndexSize: number;
}) => {
  const { nIndexes, totalIndexSize } = props;
  // TODO: Need to incorporate Unused Index stats

  const params = useParams();

  const { isLoading, data: topIndexesBySize } = useQuery({
    queryKey: ["sizingTopIndexes", params.id],
    queryFn: () =>
      getTopIndexes({
        sizingId: params.id!,
        numIndexes: QUERY_LIMIT,
      }),
    staleTime: Infinity,
    useErrorBoundary: true,
    onSuccess: (data) => {
      setRecords(data.map((index, i) => ({ ...index, id: i })));
    },
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "size",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState<Array<topIndexes>>([]);

  useEffect(() => {
    const data = sortBy(records, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  const PAGE_FROM = (page - 1) * PAGE_SIZE;
  const PAGE_TO = PAGE_FROM + PAGE_SIZE;

  const totalTopIndexSize = topIndexesBySize!
    .slice(0, 5)
    .reduce((partialSum, a) => partialSum + a.size, 0);
  const topNPercentage = (totalTopIndexSize / totalIndexSize) * 100;

  return (
    <>
      <Text align="center" weight={700} size="xl">
        {`Top ${QUERY_LIMIT} Indexes`}
      </Text>
      <Group position="center" noWrap>
        <Box w={500}>
          <StatsProgressCompare
            data={topIndexesBySize!
              .map((item) => {
                return { label: item.indexName, value: item.size };
              })
              .slice(0, 5)}
            fillPercent={95}
            unit={"byte"}
          />
        </Box>
        <Stack spacing={0} align="center">
          <RingProgress
            size={150}
            thickness={25}
            label={
              <Text color="blue" weight={700} align="center" size="sm">
                {topNPercentage.toFixed(1)}%
              </Text>
            }
            sections={[
              {
                value: topNPercentage,
                color: "teal.6",
                tooltip: `Top 5 Indexes - ${formatBytes(totalTopIndexSize)}`,
              },
              {
                value: 100 - topNPercentage,
                color: "cyan.6",
                tooltip: `All Other Indexes - ${formatBytes(
                  totalIndexSize - totalTopIndexSize
                )}`,
              },
            ]}
          />

          <Text>
            Total Indexes: {nIndexes || "N/A"} or{" "}
            {formatBytes(totalIndexSize, 1)}
          </Text>
          {/* <Text>
            Unused Indexes: {unusedIndexes.length} or{" "}
            {formatBytes(totalUnusedIndexSize, 1)}
          </Text> */}
        </Stack>
      </Group>
      <DataTable
        mt="sm"
        withBorder
        withColumnBorders
        minHeight={200}
        striped
        columns={[
          {
            accessor: "dbName",
            title: "Namespace",
            width: 280,
            render: ({ dbName, collectionName }) => {
              return `${dbName}.${collectionName}`;
            },
            ellipsis: true,
          },
          {
            accessor: "indexName",
            title: "Index",
            width: 440,
            render: ({ key }) => {
              return JSON.stringify(key);
            },
            ellipsis: true,
          },
          {
            accessor: "ops",
            title: "Accesses",
            sortable: true,
            width: 120,
            textAlignment: "right",
            render: ({ ops }) => {
              return formatNumber(ops, 1);
            },
          },
          {
            accessor: "size",
            title: "Size",
            sortable: true,
            width: 90,
            textAlignment: "right",
            render: ({ size }) => {
              return formatBytes(size, 1);
            },
          },
        ]}
        totalRecords={topIndexesBySize!.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        records={records.slice(PAGE_FROM, PAGE_TO)}
        idAccessor={"id"}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        noRecordsText="No Index Information"
      />
    </>
  );
};

export default StatComponentTopIndexes;
