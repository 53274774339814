import { ActionIcon, Group, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCheck, IconEdit, IconX } from "@tabler/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { updateSizingClusterName } from "realm/datahooks";

const EditClusterName = (props: {
  sizingId: string;
  clusterName: string;
  isSizingOwner: boolean;
}) => {
  const { clusterName, isSizingOwner, sizingId } = props;

  const [editMode, setEditMode] = useState(false);
  const [lastSavedState, setLastSavedState] = useState(clusterName);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      clusterName: lastSavedState,
    },

    validate: {
      clusterName: (value) =>
        value.length < 2 ? "Cluster Name Too Short" : null,
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateSizingClusterName,
    onSuccess: () => {
      setLastSavedState(form.values.clusterName);
      form.resetDirty(form.values);
      form.resetTouched();
      setEditMode(false);
      queryClient.invalidateQueries({
        queryKey: ["sizing", props.sizingId.toString()],
      });
    },
  });

  const { mutate: mutateClusterName } = mutation;

  return editMode ? (
    <Group spacing={4}>
      <TextInput {...form.getInputProps("clusterName")} />
      <ActionIcon
        onClick={() => {
          mutateClusterName({
            _id: sizingId,
            payload: form.values.clusterName,
          });
          setEditMode(false);
        }}
        disabled={!form.isValid()}
        color="green"
      >
        <IconCheck size={18} />
      </ActionIcon>
      <ActionIcon
        onClick={() => {
          setEditMode(false);
          form.setValues({ clusterName: lastSavedState });
          form.resetDirty({ clusterName: lastSavedState });
        }}
        color="red"
      >
        <IconX size={18} />
      </ActionIcon>
    </Group>
  ) : (
    <Group spacing={4}>
      <Text>{clusterName}</Text>
      {isSizingOwner && (
        <ActionIcon onClick={() => setEditMode(true)}>
          <IconEdit color="grey" size={18} />
        </ActionIcon>
      )}
    </Group>
  );
};

export default EditClusterName;
