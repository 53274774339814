import {
  Button,
  List,
  Modal,
  Progress,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  IconCircleCheck,
  IconCircleDashed,
  IconExclamationMark,
  IconSquare,
} from "@tabler/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useMongoChangeStream from "realm/useMongoChangeStream";
import { invokeFileProcessingLambda } from "utils/lambda-invoke-processing";
import { sizing_status } from "./upload-status-data";
import { SizingStatus } from "realm/types";

const PendingIcon = (
  <ThemeIcon color="dark.0" size={24} radius="xl">
    <IconSquare size={16} />
  </ThemeIcon>
);
const CompleteIcon = (
  <ThemeIcon color="green" size={24} radius="xl">
    <IconCircleCheck size={16} />
  </ThemeIcon>
);
const CurrentIcon = (
  <ThemeIcon color="blue" size={24} radius="xl">
    <IconCircleDashed size={16} />
  </ThemeIcon>
);
const SkippedIcon = (
  <ThemeIcon color="yellow" size={24} radius="xl">
    <IconExclamationMark size={16} />
  </ThemeIcon>
);

const FailedIcon = (
  <ThemeIcon color="red" size={24} radius="xl">
    <IconExclamationMark size={16} />
  </ThemeIcon>
);

const UploadProgress = (props: {
  opened: boolean;
  sizingIdentifier: string;
}) => {
  const { opened, sizingIdentifier } = props;

  const { document, startWatch, endWatch } = useMongoChangeStream();

  const [uploadStatus, setUploadStatus] =
    useState<SizingStatus[]>(sizing_status);

  useEffect(() => {
    if (document) {
      const status = document.fullDocument.status;
      setUploadStatus(status);
    }
  }, [document]);

  // Generate a function to determine the index of the next item where s = 0
  const getNextPendingIndex = () => {
    const nextIndex = uploadStatus.findIndex((status) => status.s === 0);
    return nextIndex;
  };

  const totalSteps = sizing_status.length;
  const currentStep = getNextPendingIndex();
  const progress = Math.floor(
    (currentStep === -1 ? totalSteps : currentStep / totalSteps) * 100
  );
  const isComplete = currentStep === -1;

  const navigate = useNavigate();

  useEffect(() => {
    if (sizingIdentifier) {
      startWatch(sizingIdentifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizingIdentifier]);

  useEffect(() => {
    if (currentStep === 2) {
      console.log("Starting Sizing Lambda for: ", sizingIdentifier);
      invokeFileProcessingLambda(sizingIdentifier).then((data) =>
        console.log("success: ", data)
      );
    }
  }, [currentStep]);

  return (
    <Modal opened={opened} onClose={() => {}} centered withCloseButton={false}>
      <Stack spacing="md">
        <Text size="xl" ta="center" fw={700}>
          Upload Progress
        </Text>
        <List spacing="xs" size="sm" center>
          {uploadStatus.map((status, index) => {
            let icon;
            if (index === currentStep) {
              icon = CurrentIcon;
            } else {
              switch (status.s) {
                case 1:
                  icon = CompleteIcon;
                  break;
                case 2:
                  icon = FailedIcon;
                  break;
                case 3:
                  icon = SkippedIcon;
                  break;
                default:
                  icon = PendingIcon;
                  break;
              }
            }
            return (
              <List.Item key={index} icon={icon}>
                {status.m}
              </List.Item>
            );
          })}
        </List>
        <Progress
          color={isComplete ? "green" : "blue"}
          striped={!isComplete}
          animate={!isComplete}
          value={Math.min(progress, 100)} // Limit the value to a maximum of 100
          label={`${Math.min(progress, 100)}%`} // Limit the label to a maximum of "100%"
          size="xl"
          radius="xl"
        />
        <Button
          disabled={!isComplete}
          onClick={() => {
            endWatch();
            navigate(`/view/${sizingIdentifier}`);
          }}
        >
          Continue
        </Button>

        <Button
          variant="light"
          onClick={() => {
            console.log("Starting Sizing Lambda for: ", sizingIdentifier);
            invokeFileProcessingLambda(sizingIdentifier).then((data) =>
              console.log("success: ", data)
            );
          }}
        >
          Invoke Lambda
        </Button>
        <Button onClick={() => startWatch(sizingIdentifier)}>
          Start Watch
        </Button>
        <Button onClick={() => endWatch()}>End Watch</Button>
        <Button
          variant="light"
          onClick={() => {
            console.log("Current Step: ", currentStep);
            console.log("Steps: ", uploadStatus);
          }}
        >
          Console Log
        </Button>
      </Stack>
    </Modal>
  );
};

export default UploadProgress;
