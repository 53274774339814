import { Button } from "@mantine/core";
import CsvDownloader from "react-csv-downloader";

type Datas = { [key: string]: any }[];

const DownloadCSVButton = (props: {
  filename: string;
  datas: Datas | (() => Datas) | (() => Promise<Datas>) | Promise<Datas>;
}) => {
  const validFileName = props.filename
    .replace(/[^a-zA-Z0-9]/g, "_")
    .toLowerCase();
  return (
    <>
      <CsvDownloader
        filename={validFileName}
        extension=".csv"
        separator=","
        // wrapColumnChar="'"
        // columns={columns}
        datas={props.datas}
      >
        <Button>Download CSV</Button>
      </CsvDownloader>
    </>
  );
};

export default DownloadCSVButton;
