import { getCurrentUser } from "./authentication";
import {
  Account,
  ErrorMessage,
  GenerateSizingInput,
  Opportunities,
  TalkingPoint,
  SearchSizing,
  Sizing,
  SizingDB,
  topCollections,
  topIndexes,
} from "./types";

export const searchAccount = (searchTerm: string): Promise<Account[]> => {
  return getCurrentUser()!.functions.searchSalesForceAccount({ searchTerm });
};

export const searchSizedAccounts = (
  searchTerm: string
): Promise<SearchSizing[]> => {
  return getCurrentUser()!.functions.searchSizedAccounts({ searchTerm });
};

export const uploadSizing = (payload: Sizing) => {
  return getCurrentUser()!.functions.uploadNewSizing(payload);
};

export const getSizing = (_id: string): Promise<Sizing | null> => {
  return getCurrentUser()!.functions.getSizing(_id);
};

// TODO: Deprecate this function after validating it's not needed
// export const getSizingsBySfid = (props: {
//   sfid: string;
//   sizingOwner: string;
// }): Promise<Sizing[]> => {
//   return getCurrentUser()!.functions.getSizingsBySfid(props);
// };

// TODO: Deprecate this function after validating it's not needed
// export const getAllSizings = (): Promise<Sizing[]> => {
//   return getCurrentUser()!.functions.getAllSizings();
// };

export const getClusterNames = (sfid: string): Promise<string[]> => {
  return getCurrentUser()!.functions.getClusterNames(sfid);
};

export const uploadErrorMessage = (payload: ErrorMessage): Promise<any> => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return new Promise(() => {});
  } else {
    return getCurrentUser()!.functions.uploadErrorMessage(payload);
  }
};

export const getOpportunities = (sfid: string): Promise<Opportunities[]> => {
  if (sfid) {
    return getCurrentUser()!.functions.getOpportunities(sfid);
  } else {
    return new Promise((resolve, reject) => resolve([]));
  }
};

export const deleteSizing = (
  _id: string
): Promise<{ deletedCount: number }> => {
  return getCurrentUser()!.functions.deleteSizing(_id);
};

export const getCollectionCSVData = (
  _id: string
): Promise<
  {
    db: string;
    collection: string;
    dataSize: number;
    storageSize: number;
    avgObjSize: number;
    indexSize: number;
    nIndexes: number;
  }[]
> => {
  return getCurrentUser()!.functions.getCollectionCSVData(_id);
};

export const updateTalkingPoints = (props: {
  _id: string;
  payload: TalkingPoint[];
}) => {
  return getCurrentUser()!.functions.updateSizing({
    _id: props._id,
    payload: { talkingPoints: props.payload },
  });
};

export const generateSizing = (payload: GenerateSizingInput) => {
  const { bestPracticeValues, ...args } = payload;
  return getCurrentUser()!.functions.generateSizing(args, bestPracticeValues);
};

export const updateSizingClusterName = (props: {
  _id: string;
  payload: string;
}) => {
  return getCurrentUser()!.functions.updateSizing({
    _id: props._id,
    payload: { clusterName: props.payload },
  });
};

// TO BE IMPLEMENTED
// Get All Databases
export const getTopDatabasesAndCollections = (props: {
  sizingId: string;
  numDatabases?: number;
  numCollections?: number;
}): Promise<Array<SizingDB>> => {
  return getCurrentUser()!.functions.getTopDatabasesAndCollections({
    sizingId: props.sizingId,
    numDatabases: props.numDatabases,
    numCollections: props.numCollections,
  });
};

// Get All Collections
export const getTopCollections = (props: {
  sizingId: string;
  // May want to include a DB Name Identifier? Otherwise this will get the Top Collections, regardless of Database
  numCollections?: number;
}): Promise<Array<topCollections>> => {
  return getCurrentUser()!.functions.getTopCollections({
    sizingId: props.sizingId,
    numCollections: props.numCollections,
  });
};

// Get Top Indexes
export const getTopIndexes = (props: {
  sizingId: string;
  numIndexes?: number;
}): Promise<Array<topIndexes>> => {
  return getCurrentUser()!.functions.getTopIndexes({
    sizingId: props.sizingId,
    numIndexes: props.numIndexes,
  });
};

// On form submit, open a MongoDB Changestream to watch for the status field
// export const openMongoDBChangeStream = (sizingId: string) => {
//   const mongodb = getCurrentUser()!.mongoClient("mongodb-atlas");
//   const collection = mongodb.db("msizer-dev").collection("sizings"); // TODO: Database name will need to be dynamic based on environment

//   const changeStream = collection.watch([
//     { $match: { "fullDocument._id": sizingId }, operationType: "update" },
//   ]);

//   return changeStream;
// };

export const updateSizing = (props: { _id: string; payload: Object }) => {
  return getCurrentUser()!.functions.updateSizing({
    _id: props._id,
    payload: props.payload,
  });
};
