import {
  IconClock,
  IconContainer,
  IconCpu,
  IconDimensions,
  IconFilePercent,
  IconLink,
  IconWindmill,
  IconWindow,
} from "@tabler/icons";
import { SizingData, TalkingPoint } from "realm/types";
import { StatsCard } from "../components/stats-card";
import { formatBytes } from "utils/format-bytes";
import {
  StatsDataTable,
  StatTableElementProps,
} from "../components/stats-data-table";
import { Space } from "@mantine/core";
import formatNumber from "utils/format-number";
import formatHumanizeSeconds from "utils/format-duration";
import { getTalkingPointIndex, showError } from "../utils/red-flag-utils";

const infoTooltip =
  "CPU and RAM can be used as a starting point for sizing a cluster in Atlas. If there are signs of performance issues, we'll need to investigate further.";

const StatComponentServerDetails = (props: {
  data: SizingData;
  talkingpoints: TalkingPoint[];
}) => {
  const { data, talkingpoints } = props;
  const { serverInfo } = data;

  const replicationInfoMissingIndex = getTalkingPointIndex(
    "replicationInfoMissing",
    talkingpoints
  );

  const replicationSmallWindowIndex = getTalkingPointIndex(
    "replicationSmallWindow",
    talkingpoints
  );

  const serverSpecElements: StatTableElementProps[] = [
    {
      icon: IconCpu,
      name: "CPU Cores",
      value: serverInfo.numCores,
    },
    {
      icon: IconContainer,
      name: "RAM",
      value: formatBytes(serverInfo.memSizeMB * 1000 ** 2), // NOTE: Displayed as Megabyte not Mebibyte
    },
    {
      icon: IconClock,
      name: "Uptime",
      value: <div>{formatHumanizeSeconds(serverInfo.uptimeSec)}</div>,
    },
    {
      icon: IconLink,
      name: "Connections",
      value: `${formatNumber(serverInfo.connections.current, 0)} Open`,
    },
  ];

  const oplogStatElements: StatTableElementProps[] = data.replicationInfo
    ? [
        {
          icon: IconDimensions,
          name: "Size",
          value: formatBytes(data.replicationInfo.logSizeMB * 1000 ** 2),
        },
        {
          icon: IconFilePercent,
          name: "Used",
          value: formatBytes(data.replicationInfo.usedMB * 1000 ** 2),
        },
        {
          icon: IconWindow,
          name: "Window",
          value: `${data.replicationInfo.timeDiffHours} Hrs`,
          error: showError(replicationSmallWindowIndex, talkingpoints),
          errorTitle: talkingpoints[replicationSmallWindowIndex]?.title,
          errorMessage: talkingpoints[replicationSmallWindowIndex]?.message,
        },
        {
          icon: IconWindmill,
          name: "Rate",
          value: `${formatBytes(
            (data.replicationInfo.usedMB * 1000 ** 2) /
              data.replicationInfo.timeDiffHours
          )} / Hr`,
        },
      ]
    : [
        {
          icon: IconDimensions,
          name: "Replication Info",
          value: "N/A",
          error: showError(replicationInfoMissingIndex, talkingpoints),
          errorTitle: talkingpoints[replicationInfoMissingIndex]?.title,
          errorMessage: talkingpoints[replicationInfoMissingIndex]?.message,
        },
      ];

  return (
    <StatsCard title={"Server Details"} infoTooltip={infoTooltip}>
      <StatsDataTable title={"Server Specs"} records={serverSpecElements} />
      <Space h="sm" />
      <StatsDataTable title={"Oplog Stats"} records={oplogStatElements} />
    </StatsCard>
  );
};

export default StatComponentServerDetails;
