import { useRef, useState } from "react";
import { getCurrentUser } from "./authentication";

// Define the hook type, specifying the type of data expected from the change stream
interface UseMongoChangeStream<T> {
  document: T | null;
  startWatch: (documentId: string) => void;
  endWatch: () => void;
}

function useMongoChangeStream(): UseMongoChangeStream<any> {
  const [document, setDocument] =
    useState<Realm.Services.MongoDB.Document | null>(null);

  const changestreamRef = useRef<AsyncGenerator<
    Realm.Services.MongoDB.ChangeEvent<any>,
    any,
    unknown
  > | null>(null);

  const startWatch = (documentId: string) => {
    if (!documentId) {
      console.error("No documentId provided");
      return;
    }
    if (changestreamRef.current) {
      console.log("Changestream already open, so returning");
      return;
    }

    // Initialize MongoDB client
    const mongodb = getCurrentUser()!.mongoClient("mongodb-atlas");
    const collection = mongodb.db("msizer-dev").collection("sizings"); // TODO: Database name will need to be dynamic based on environment

    const connectAndStream = async () => {
      try {
        console.log("Starting Changestream for: ", documentId);
        // Create a change stream with a filter to listen only for changes to the specific document
        const changeStream = collection.watch([
          {
            $match: { "fullDocument._id": documentId },
            operationType: "update",
          },
        ]);

        changestreamRef.current = changeStream;

        for await (const change of changeStream) {
          setDocument(change);
        }
      } catch (connectError) {
        console.error("connectError: ", connectError);
      }
    };

    connectAndStream();
  };

  const endWatch = () => {
    if (changestreamRef.current) {
      console.log("Ending Changestream");
      changestreamRef.current.return("");
      changestreamRef.current = null;
    }
  };

  return { document, startWatch, endWatch };
}

export default useMongoChangeStream;
