import {
  Group,
  HoverCard,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconAbacus,
  IconDatabase,
  IconScript,
  IconTopologyRing2,
} from "@tabler/icons";
import { forwardRef } from "react";
import { TalkingPoint, SizingData } from "realm/types";

import { StatsCard } from "../components/stats-card";
import {
  StatsDataTable,
  StatTableElementProps,
} from "../components/stats-data-table";
import {
  buildError,
  getTalkingPointIndex,
  showError,
} from "../utils/red-flag-utils";
import { determineNodeTypes } from "../utils/sizing-processors";

const StatComponentClusterProfile = (props: {
  data: SizingData;
  talkingpoints: TalkingPoint[];
}) => {
  const theme = useMantineTheme();

  const { data, talkingpoints } = props;
  const NODE_SIZE = 75;

  const replicaConfig = data.replicaSetConfig;

  const nodeColors = {
    Primary: theme.colors.green[8],
    Secondary: theme.colors.cyan[8],
    Arbiter: theme.colors.orange[9],
    Hidden: theme.colors.violet[7],
    Delayed: theme.colors.indigo[9],
    Electable: "blue",
  };

  const processedNodeTypes = determineNodeTypes(replicaConfig);

  interface NodeComponentProps {
    color: string;
  }

  const NodeComponent = forwardRef<HTMLDivElement, NodeComponentProps>(
    ({ color }: NodeComponentProps, ref) => (
      <div ref={ref} {...props}>
        <IconDatabase size={NODE_SIZE} color={color} />
      </div>
    )
  );

  const clusterTopologyError = buildError(
    "clusterNodeCountSingle",
    talkingpoints
  );

  const clusterNodeCountError = (talkingPoints: TalkingPoint[]) => {
    const singleIndex = getTalkingPointIndex(
      "clusterNodeCountSingle",
      talkingPoints
    );
    const evenIndex = getTalkingPointIndex(
      "clusterNodeCountEven",
      talkingPoints
    );
    const index = Math.max(singleIndex, evenIndex);

    return {
      error: showError(index, talkingPoints),
      errorTitle: talkingPoints[index]?.title,
      errorMessage: talkingPoints[index]?.message,
    };
  };

  const clusterNodeArbiterIndex = getTalkingPointIndex(
    "clusterNodeArbiter",
    talkingpoints
  );
  const clusterNodeDelayedIndex = getTalkingPointIndex(
    "clusterNodeHidden",
    talkingpoints
  );
  const clusterNodeHiddenIndex = getTalkingPointIndex(
    "clusterNodeDelayed",
    talkingpoints
  );

  const clusterNodeErrors: string[] = [];
  if (showError(clusterNodeArbiterIndex, talkingpoints)) {
    clusterNodeErrors.push(talkingpoints[clusterNodeArbiterIndex].message);
  }
  if (showError(clusterNodeHiddenIndex, talkingpoints)) {
    clusterNodeErrors.push(talkingpoints[clusterNodeHiddenIndex].message);
  }
  if (showError(clusterNodeDelayedIndex, talkingpoints)) {
    clusterNodeErrors.push(talkingpoints[clusterNodeDelayedIndex].message);
  }

  const clusterProfileElements: StatTableElementProps[] = [
    {
      icon: IconTopologyRing2,
      name: "Type",
      value: data.members > 1 ? "Replica Set" : "Single Node",
      ...clusterTopologyError,
    },
    {
      icon: IconScript,
      name: "Configuration",
      value: processedNodeTypes
        .map((node) => {
          return node.short;
        })
        .join(""),
      error: clusterNodeErrors.length > 0,
      errorTitle: "Non-Electable Nodes",
      errorMessage: clusterNodeErrors,
    },
    {
      icon: IconAbacus,
      name: "Members",
      value: data.members || 1,
      ...clusterNodeCountError(talkingpoints),
    },
  ];

  return (
    <StatsCard title={"Cluster Profile"}>
      <Group grow align="center">
        <StatsDataTable title="Server Specs" records={clusterProfileElements} />

        <Group position="center">
          {processedNodeTypes.map((node) => {
            return (
              <Stack key={node._id} spacing={0} align="center">
                <HoverCard withArrow shadow="md">
                  <HoverCard.Target>
                    <div>
                      <NodeComponent color={nodeColors[node.long]} />
                    </div>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Table striped highlightOnHover withBorder>
                      <tbody>
                        <tr>
                          <td>Hostname</td>
                          <td>{node.host}</td>
                        </tr>
                        <tr>
                          <td>Priority</td>
                          <td>{node.priority}</td>
                        </tr>
                        <tr>
                          <td>Votes</td>
                          <td>{node.votes}</td>
                        </tr>
                        <tr>
                          <td>Tags</td>
                          <td>
                            <pre>
                              {JSON.stringify(node.tags, null, 0)
                                .slice(1, -1)
                                .split(",")
                                .join("\n")}
                            </pre>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </HoverCard.Dropdown>
                </HoverCard>
                <Text>{node.long}</Text>{" "}
                <Text size={10} color="dimmed">
                  {/Primary|Secondary/.test(node.long)
                    ? "Electable"
                    : node.long}
                </Text>
              </Stack>
            );
          })}
        </Group>
      </Group>
    </StatsCard>
  );
};

export default StatComponentClusterProfile;
