import { getCurrentUser } from "realm/authentication";
import { SearchSizingResponse } from "../types/search-types";

// TODO: This function should be implemented in the datahooks file

export const searchSizings = (
  searchTerm: string
): Promise<SearchSizingResponse[]> => {
  return getCurrentUser()!.functions.searchByAccountOrOwner({ searchTerm });
};
