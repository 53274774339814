import { Text, Group, Accordion } from "@mantine/core";
import {
  IconCalendar,
  IconExclamationCircle,
  IconExternalLink,
  IconFileInvoice,
  IconReportMoney,
  IconServer2,
  IconUser,
} from "@tabler/icons";
import { BASE_URL } from "realm/app";
import { getCurrentUser } from "realm/authentication";
import { Sizing } from "realm/types";
import CopyUrlButton from "../components/copy-url-button";
import DeleteSizingModal from "../components/delete-sizing-modal";

import {
  StatsDataTable,
  StatTableElementProps,
} from "../components/stats-data-table";
import EditClusterName from "../components/edit-cluster-name";

const StatComponentSizingDetails = (props: { data: Sizing }) => {
  const { data } = props;
  const currentUser = getCurrentUser();
  const isSizingOwner = currentUser?.profile.email === data.ownerEmail;

  const serverSpecElements: StatTableElementProps[] = [
    {
      icon: IconFileInvoice,
      name: "Account",
      value: (
        <Text
          td="underline"
          c="blue"
          component="a"
          href={`https://mongodb.my.salesforce.com/${data.sfid}`}
          target="_blank"
          rel="noreferrer"
        >
          {data.accountName}
          <IconExternalLink size={18} />
        </Text>
      ),
    },
    {
      icon: IconReportMoney,
      name: "Opportunity",
      value:
        data.opportunityId === "None" ? (
          data.opportunityName
        ) : (
          <Text
            td="underline"
            c="blue"
            component="a"
            href={`https://mongodb.my.salesforce.com/${data.opportunityId}`}
            target="_blank"
            rel="noreferrer"
          >
            {data.opportunityName}
            <IconExternalLink size={18} />
          </Text>
        ),
    },
    {
      icon: IconServer2,
      name: "Cluster Name",
      value: (
        <EditClusterName
          clusterName={data.clusterName}
          isSizingOwner={isSizingOwner}
          sizingId={data._id!}
        />
      ),
    },
    {
      icon: IconUser,
      name: "Account Executive",
      value: data.accountOwner,
    },
    {
      icon: IconUser,
      name: "Solutions Architect",
      value: data.ownerFullName,
    },
    {
      icon: IconCalendar,
      name: "Created",
      value: data.ts.toLocaleDateString(),
    },
    {
      icon: IconExclamationCircle,
      name: "Actions",
      value: (
        <Group spacing={4}>
          {isSizingOwner && (
            <>
              <DeleteSizingModal id={data._id!} />
              {/* <IconEdit color="grey" size={18} /> */}
              {/* // Disabled until edit functionality is in place */}
            </>
          )}
          <CopyUrlButton url={`${BASE_URL}/view/${data._id}`} />
        </Group>
      ),
    },
  ];

  return (
    <Accordion
      chevronPosition="left"
      styles={{
        item: {
          border: "none",
        },
      }}
    >
      <Accordion.Item value={data.accountName}>
        <Accordion.Control>
          <Group position="left" spacing="xs">
            <Text fw={700}>{data.accountName}</Text>
            <Text size="sm">{`Cluster: ${data.clusterName}`}</Text>
          </Group>
          <Text size="xs" color="dimmed">
            Click to expand
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <StatsDataTable title="" records={serverSpecElements} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default StatComponentSizingDetails;
