import {
  createStyles,
  Divider,
  Group,
  NumberInput,
  Progress,
  Text,
  Title,
} from "@mantine/core";
import { IconPercentage } from "@tabler/icons";
import { useState } from "react";
import { formatBytes } from "utils/format-bytes";
import { StatsCard } from "../components/stats-card";
import {
  StatsDataTable,
  StatTableElementProps,
} from "../components/stats-data-table";

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 12,
  },
}));

const StatComponentWorkingSet = (props: {
  totalDataSizeMB: number;
  totalIndexSizeMB: number;
  memSizeMB: number;
  connections: number;
  wiredtigerCacheSizeMB: number;
  disableMultiplier?: boolean;
}) => {
  const { classes } = useStyles();

  const [dataMultiplier, setDataMultiplier] = useState<number>(100);

  const {
    totalDataSizeMB,
    totalIndexSizeMB,
    memSizeMB,
    connections,
    wiredtigerCacheSizeMB,
    disableMultiplier,
  } = props;

  const modifiedIndexSizeMB = totalIndexSizeMB * (dataMultiplier / 100);
  const totalRamMB = memSizeMB;
  const connectionsMB = connections || 0; // Is this value available on all versions? Assumption: 1 Connection is ~1MB of RAM
  const osReservedMB = 1000;
  const nonOsReservedMB = totalRamMB - osReservedMB - connectionsMB;
  const wiredTigerCacheMB = wiredtigerCacheSizeMB;

  const filesystemCacheMB = nonOsReservedMB - wiredTigerCacheMB;
  const documentCacheSizeMB = wiredTigerCacheMB - modifiedIndexSizeMB;

  const indexesInRAM = Math.min(modifiedIndexSizeMB, nonOsReservedMB);
  const indexesInWiredTigerCache = Math.min(indexesInRAM, wiredTigerCacheMB);
  const indexesNotInWiredTigerCahe =
    modifiedIndexSizeMB - indexesInWiredTigerCache;
  const percentIndexesInWiredTigerCache =
    (indexesInWiredTigerCache / modifiedIndexSizeMB) * 100;

  const documentsInWiredTigerCache = Math.max(0, documentCacheSizeMB);
  const documentsNotInWiredTigerCache =
    totalDataSizeMB - documentsInWiredTigerCache;

  const documentsAndIndexesInFileSystemCache = Math.min(
    documentsNotInWiredTigerCache + indexesNotInWiredTigerCahe,
    filesystemCacheMB
  );

  // const percentDataInRAM = Math.min(
  //   100,
  //   ((filesystemCacheMB + documentsInWiredTigerCache) / totalDataSizeMB) * 100
  // );

  const mbFormatter = (size: number) => {
    return formatBytes(size * 1000 ** 2);
  };

  const hardwareDataElements: StatTableElementProps[] = [
    {
      name: "WiredTiger Cache",
      value: `${mbFormatter(wiredTigerCacheMB)}`,
    },
    {
      name: "FileSystem Cache",
      value: `${mbFormatter(filesystemCacheMB)}`,
    },
    {
      name: "RAM for Connections",
      value: `${mbFormatter(connectionsMB)}`,
    },
    {
      name: "Reserved for OS",
      value: `${mbFormatter(osReservedMB)}`,
    },
  ];

  const contentsDataElements: StatTableElementProps[] = [
    {
      name: "Indexes",
      value: `${mbFormatter(indexesInWiredTigerCache)}`,
    },
    {
      name: "Documents",
      value: `${mbFormatter(documentsInWiredTigerCache)}`,
    },
  ];

  const calculatedDataElements: StatTableElementProps[] = [
    {
      name: "WT / RAM",
      value: `${Math.min(100, (wiredTigerCacheMB / totalRamMB) * 100).toFixed(
        0
      )}%`,
    },
    {
      name: "Index % in WT",
      value: `${percentIndexesInWiredTigerCache.toFixed(0)}%`,
    },
    // {
    //   name: "Max Data in RAM",
    //   value: `${percentDataInRAM.toFixed(0)}%`,
    // },
  ];

  return (
    <StatsCard title={"RAM Illustration"}>
      <Divider
        my="xs"
        label={
          <Title order={6}>{`Provisioned RAM: ${formatBytes(
            totalRamMB * 1000 ** 2
          )}`}</Title>
        }
        labelPosition="center"
      />
      <Progress
        classNames={{ label: classes.label }}
        size={30}
        sections={[
          {
            value: (wiredTigerCacheMB / totalRamMB) * 100,
            color: "lime",
            label: "WiredTiger Cache",
            tooltip: `WT Cache - ${mbFormatter(wiredTigerCacheMB)}`,
          },
          {
            value: (filesystemCacheMB / totalRamMB) * 100,
            color: "green",
            label: "File System Cache",
            tooltip: `FS Cache - ${mbFormatter(filesystemCacheMB)}`,
          },
          {
            value: (connectionsMB / totalRamMB) * 100,
            color: "cyan",
            label: "Connections",
            tooltip: `Connections - ${mbFormatter(connectionsMB)}`,
          },
          {
            value: (osReservedMB / totalRamMB) * 100,
            color: "blue.6",
            label: "OS Reserved",
            tooltip: `OS Reserved - ${mbFormatter(osReservedMB)}`,
          },
        ]}
      />
      <Group position="apart">
        <Text size="xs" color="dimmed">
          WiredTiger Cache: {mbFormatter(wiredTigerCacheMB)}
        </Text>
        <Text size="xs" color="dimmed">
          File System Cache: {mbFormatter(filesystemCacheMB)}
        </Text>
        <Text size="xs" color="dimmed">
          Connections + OS Reserved: {mbFormatter(connectionsMB + osReservedMB)}
        </Text>
      </Group>
      <Progress
        classNames={{ label: classes.label }}
        size={30}
        sections={[
          {
            value: (indexesInWiredTigerCache / totalRamMB) * 100,
            color: "grape",
            label: "Indexes",
            tooltip: `Total Index Size - ${mbFormatter(modifiedIndexSizeMB)}`,
          },
          {
            value: (documentsInWiredTigerCache / totalRamMB) * 100,
            color: "violet",
            label: "Uncompressed Documents",
            tooltip: `Uncompressed Documents - ${mbFormatter(
              documentCacheSizeMB
            )}`,
          },
          {
            value: (documentsAndIndexesInFileSystemCache / totalRamMB) * 100,
            color: "gray",
            label: "Compressed Documents & Indexes",
            tooltip: `Compressed Documents & Indexes - ${mbFormatter(
              documentsAndIndexesInFileSystemCache
            )}`,
          },
        ]}
      />
      <Group position="apart">
        <Text size="xs" color="dimmed">
          {`Indexes in WT Cache: ${mbFormatter(indexesInWiredTigerCache)}`}
        </Text>
        <Text
          size="xs"
          color={documentCacheSizeMB < 0 ? "red" : "dimmed"}
          fw={documentCacheSizeMB < 0 ? "bold" : "normal"}
        >
          {`Documents in WT Cache: ${mbFormatter(documentsInWiredTigerCache)}`}
        </Text>
      </Group>
      <Group position="center" mt="sm" align="stretch">
        <StatsDataTable
          title={"Hardware Stats"}
          records={hardwareDataElements}
        />
        <StatsDataTable
          title={"WiredTiger Stats"}
          records={contentsDataElements}
        />
        {!disableMultiplier && (
          <StatsDataTable
            title={"Calculated Results"}
            records={calculatedDataElements}
          />
        )}

        {!disableMultiplier && (
          <NumberInput
            value={dataMultiplier}
            onChange={(e) => setDataMultiplier(e || 0)}
            label="Data Multiplier"
            min={0}
            step={10}
            stepHoldDelay={500}
            stepHoldInterval={100}
            icon={<IconPercentage size={18} />}
            sx={{
              width: "110px",
            }}
          />
        )}
      </Group>
      {/* <pre>{JSON.stringify(totals, null, 2)}</pre> */}
    </StatsCard>
  );
};

export default StatComponentWorkingSet;
