import {
  Autocomplete,
  createStyles,
  Loader,
  SelectItemProps,
  Text,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { forwardRef, useEffect, useState } from "react";
import { getOpportunities } from "realm/datahooks";
import { Opportunities } from "realm/types";
import { useSizingFormContext } from "../providers/new-sizing-context";
import { formatDate } from "utils/format-date";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: "auto",
    paddingTop: 18,
    "&[data-success]": {
      border: `2px solid ${theme.colors.green[6]}`,
    },
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

interface SelectOpportunityProps {
  accountSelected: string;
}

interface ItemProps extends SelectItemProps, Opportunities {}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ nm, closeDt, stageNm, value, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text>{nm}</Text>
      <Text size="xs" color="dimmed">
        {`Stage: ${stageNm} - Close Date: ${formatDate(closeDt, "PP")}`}
      </Text>
    </div>
  )
);

const NoOppDoc: Opportunities = {
  _id: "None",
  nm: "No Active Opportunity",
  closeDt: new Date(),
  stageNm: "None",
};

const SelectOpportunity = (props: SelectOpportunityProps) => {
  const { classes } = useStyles();
  const { accountSelected } = props;

  const form = useSizingFormContext();

  const { isFetching: isOpportunitiesFetching, data: opportunityData } =
    useQuery({
      queryKey: ["opportunities", accountSelected],
      queryFn: () => getOpportunities(accountSelected),
    });

  const [opportunities, setOpportunities] = useState<Opportunities[]>([]);
  useEffect(() => {
    setOpportunities(opportunityData || []);
  }, [opportunityData]);

  useEffect(() => {
    form.setValues({ opportunityId: "", opportunityName: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelected]);

  return (
    <Autocomplete
      data-success={form.values.opportunityName[0]?.length > 0 || undefined}
      transition="pop-top-left"
      transitionDuration={80}
      transitionTimingFunction="ease"
      itemComponent={SelectItem}
      mt="md"
      styles={(theme) => ({
        item: {
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: "transparent",
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.teal[6]
                  : theme.colors.teal[9],
            },
          },
        },
      })}
      data={[NoOppDoc, ...opportunities].map((item) => ({
        ...item,
        key: `${item._id}`,
        label: `${item.nm}`,
        value: `${item.nm}`,
      }))}
      placeholder={
        !accountSelected
          ? "Select Account First"
          : 'Select Active Opp or "No Opportunity"'
      }
      label="Opportunity"
      classNames={classes}
      disabled={!accountSelected || isOpportunitiesFetching}
      rightSection={
        isOpportunitiesFetching ? (
          <Loader size={16} />
        ) : form.values.opportunityName[0]?.length > 0 ? (
          <IconCheck size={16} color="green" />
        ) : null
      }
      required
      onItemSubmit={(e) => {
        form.setValues({ opportunityId: e._id, opportunityName: e.nm });
      }}
      // filter={(value, item) => true}
      {...form.getInputProps("opportunityName")}
      // onChange={(e) => {}} // This disables typing
    />
  );
};

export default SelectOpportunity;
