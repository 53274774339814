import { app, REDIRECT_URL } from "./app";
import * as Realm from "realm-web";

export const hasLoggedInUser = (): boolean => {
  return app.currentUser ? app.currentUser.isLoggedIn : false;
};

export const getCurrentUser = () => {
  return hasLoggedInUser() ? app.currentUser : null;
};

export const logoutCurrentUser = async () => {
  return await app.currentUser!.logOut();
};

export const loginGoogle = async () => {
  const credentials = Realm.Credentials.google({ redirectUrl: REDIRECT_URL });
  return await app.logIn(credentials);
};

// export const addAuthListener = (listner: () => {}) => {
//   return app.addListener(listner);
// };

export const handleOauthRedirect = () => {
  Realm.handleAuthRedirect();
};
