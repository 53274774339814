import { DataTable } from "mantine-datatable";
import { Sizing } from "realm/types";
import { Text } from "@mantine/core";
import DeleteSizingModal from "features/view-sizing/components/delete-sizing-modal";

const SizedClusterDataTable = (props: { records: Sizing[] }) => {
  const rows = props.records.sort((a, b) => b.ts.getTime() - a.ts.getTime());

  return (
    <DataTable
      withBorder
      highlightOnHover
      ml={50}
      columns={[
        {
          accessor: "clusterName",
          title: "Cluster Name",
          render: ({ _id, clusterName }) => (
            <Text component="a" href={`/view/${_id}`} c="blue" inherit>
              {clusterName}
            </Text>
          ),
        },
        {
          accessor: "ownerFullName",
          title: "Solutions Architect",
          textAlignment: "right",
        },
        {
          accessor: "ts",
          title: "Created",
          render: ({ ts }) => ts.toLocaleDateString(),
          width: 100,
          textAlignment: "center",
        },
        {
          accessor: "",
          title: "",
          render: ({ _id }) => <DeleteSizingModal id={`${_id}`} />,
          width: 50,
        },
      ]}
      records={rows}
      idAccessor="_id"
    />
  );
};

export default SizedClusterDataTable;
