import { abbreviateNumber } from "js-abbreviation-number";

const formatNumber = (number: number, decimals: number) => {
  return abbreviateNumber(number, decimals, {
    symbols: ["", " K", " M", " B", " T", " P", " E"],
    padding: true,
  });
};

export default formatNumber;
