// import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Tooltip } from "@mantine/core";
import { sortBy } from "lodash";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { SizingCollectionStats } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import formatNumber from "utils/format-number";

const PAGE_SIZE = 5;

const IndexStatsTable = (props: { data: SizingCollectionStats }) => {
  const { data } = props;

  const indexSizes = data.indexes.indexSizes;
  const indexStats = data.indexes.stats;

  const rows = indexStats.map((index) => {
    return { ...index, size: indexSizes[index.name] as number };
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "size",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(rows.slice(0, PAGE_SIZE));

  useEffect(() => {
    const data = sortBy(rows, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const PAGE_FROM = (page - 1) * PAGE_SIZE;
  const PAGE_TO = PAGE_FROM + PAGE_SIZE;

  // const { classes } = useStyles();
  // const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);

  // const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

  return (
    <DataTable
      // bodyRef={bodyRef}
      ml="md"
      // my="xs"
      withBorder
      recordsPerPage={PAGE_SIZE}
      page={page}
      onPageChange={(p) => setPage(p)}
      records={records.slice(PAGE_FROM, PAGE_TO)}
      totalRecords={rows.length}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      // highlightOnHover
      idAccessor="name"
      columns={[
        {
          accessor: "key",
          title: "Index Definition",
          width: 200,
          render: ({ key }) => (
            <pre style={{ margin: 0 }}>
              {JSON.stringify(key, null, 0).slice(1, -1).split(",").join("\n")}
            </pre>
          ),
        },
        {
          accessor: "accesses.ops",
          title: "Accesses",
          textAlignment: "center",
          sortable: true,
          width: 100,
          render: ({ accesses }) => (
            <Tooltip label={`Since ${accesses.since.toLocaleDateString()}`}>
              <div>{formatNumber(accesses.ops, 0)}</div>
            </Tooltip>
          ),
        },
        {
          accessor: "size",
          textAlignment: "right",
          sortable: true,
          width: 100,
          render: ({ size }) => <>{formatBytes(size)}</>,
        },
      ]}
    />
  );
};

export default IndexStatsTable;
