import ReactMarkdown from "react-markdown";
import { Text } from "@mantine/core";

const MarkDown = (props: { md: string }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <Text {...props} />,
        em: ({ node, ...props }) => (
          <Text fw={700} underline span inline {...props} />
        ),
      }}
    >
      {props.md}
    </ReactMarkdown>
  );
};

export default MarkDown;
