import { DataTable } from "mantine-datatable";
import { MongoDBInstance } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import formatNumber from "utils/format-number";

const SizingSummaryTable = (props: {
  instances: MongoDBInstance[];
  rowClick: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { instances, rowClick } = props;

  const identifiedInstances = instances.map((instance, i) => {
    return { id: i, ...instance };
  });

  return (
    <DataTable
      withBorder
      striped
      minHeight={150}
      noRecordsText="No Suggested Sizings Available"
      columns={[
        {
          accessor: "id",
          title: "Type",
          render: ({ shardConfig }) => {
            const shardCount = shardConfig?.shardCount || 1;
            return shardCount === 1 ? "Replica Set" : `${shardCount} Shards`;
          },
        },
        {
          accessor: "tier",
          textAlignment: "center",
        },
        {
          accessor: "vCPU",
          title: "CPU",
          textAlignment: "center",
          render: ({ vCPU, shardConfig }) => {
            const shardCount = shardConfig?.shardCount || 1;
            return vCPU * shardCount;
          },
        },
        {
          accessor: "ramGB",
          title: "RAM",
          textAlignment: "center",
          render: ({ ramGB, shardConfig }) => {
            const shardCount = shardConfig?.shardCount || 1;
            return `${ramGB * shardCount} GB`;
          },
        },
        {
          accessor: "wiredTigerSizeMB",
          title: "WiredTiger",
          textAlignment: "center",
          render: ({ wiredTigerSizeMB, shardConfig }) => {
            const shardCount = shardConfig?.shardCount || 1;
            return formatBytes(wiredTigerSizeMB * shardCount * 1000 ** 2);
          },
        },
        {
          accessor: "storageLimitGB",
          title: "Disk",
          textAlignment: "center",
          render: ({ storageLimitGB, shardConfig }) => {
            const shardCount = shardConfig?.shardCount || 1;
            return formatBytes(storageLimitGB * shardCount * 1000 ** 3);
          },
        },

        {
          accessor: "maxIOPS",
          title: "IOPS",
          textAlignment: "center",
          render: ({ maxIOPS, shardConfig }) => {
            const shardCount = shardConfig?.shardCount || 1;
            return formatNumber(maxIOPS * shardCount, 0);
          },
        },
        {
          accessor: "pricePerHour",
          title: "Hourly Rate",
          textAlignment: "right",
          render: ({ pricePerHour, shardConfig }) => {
            const shardCount = shardConfig?.shardCount || 1;
            return pricePerHour
              ? `~$${(pricePerHour * shardCount).toFixed(2)} USD`
              : " ";
          },
        },
      ]}
      records={identifiedInstances}
      idAccessor="id"
      onRowClick={(row, rowIndex) => {
        rowClick(rowIndex + 1);
      }}
    />
  );
};

export default SizingSummaryTable;
