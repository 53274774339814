import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";

// Define the type for the response from Lambda
interface LambdaResponse {
  StatusCode: number | undefined;
  Payload: string | undefined;
}

// Utility function to invoke AWS Lambda
export const invokeFileProcessingLambda = async (
  sizingId: string
): Promise<LambdaResponse> => {
  const {
    REACT_APP_ACCESS_KEY_ID,
    REACT_APP_SECRET_ACCESS_KEY,
    REACT_APP_S3_BUCKET: S3_BUCKET,
  } = process.env;

  const LAMBDA_REGION = "us-east-1";
  const LAMBDA_FUNCTION = "msizer-dev-process-s3-file"; // TODO: Make this environment dynamic

  try {
    // Configure AWS Lambda client
    const lambdaClient = new LambdaClient({
      region: LAMBDA_REGION, // Replace with your AWS region
      credentials: {
        accessKeyId: REACT_APP_ACCESS_KEY_ID!, // Use environment variables for security
        secretAccessKey: REACT_APP_SECRET_ACCESS_KEY!,
      },
    });

    // Create the command to invoke the Lambda function
    const command = new InvokeCommand({
      FunctionName: LAMBDA_FUNCTION,
      Payload: JSON.stringify({ bucket: S3_BUCKET, sizingId }),
    });

    // Invoke the Lambda function
    const response = await lambdaClient.send(command);

    // Decode the response payload
    const decodedPayload = response.Payload
      ? new TextDecoder("utf-8").decode(response.Payload)
      : "";

    return {
      StatusCode: response.StatusCode,
      Payload: decodedPayload,
    };
  } catch (error) {
    console.error("Error invoking Lambda:", error);
    throw new Error("Failed to invoke Lambda function");
  }
};
