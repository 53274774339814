import {
  createStyles,
  Autocomplete,
  Loader,
  SelectItemProps,
  Text,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { forwardRef, useState } from "react";
import { searchSizings } from "../data";
import { SearchSizingInput, SearchSizingResponse } from "../types/search-types";
import { getCurrentUser } from "realm/authentication";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

interface SearchSizedAccountsProps {
  accountSelected: SearchSizingInput;
  setAccountSelected: React.Dispatch<React.SetStateAction<SearchSizingInput>>;
}

interface ItemProps extends SelectItemProps, SearchSizingResponse {}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ _id, highlights, value, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text>
        {highlights
          ? highlights.texts.map((highlight, index) => {
              return highlight.type === "hit" ? (
                <b key={highlight.type + index}>{highlight.value}</b>
              ) : (
                <span key={highlight.type + index}>{highlight.value}</span>
              );
            })
          : `${value}`}
      </Text>
    </div>
  )
);

export function SearchSizedAccounts(props: SearchSizedAccountsProps) {
  const { classes } = useStyles();

  const currentUser = getCurrentUser();

  const { accountSelected, setAccountSelected } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [debounced] = useDebouncedValue(searchTerm, 200);

  const {
    data: searchData,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
  } = useQuery({
    queryKey: ["searchSizing", debounced],
    queryFn: () => searchSizings(debounced),
    enabled: accountSelected.value === currentUser?.profile.name, // Disabled if showing own sizings
    keepPreviousData: true,
  });

  const searchResults = () => {
    if (!searchData) return [];

    return searchData.map((item) => {
      if (item._id.type === "accountName") {
        return {
          ...item,
          key: `${item._id.value.sfid}`,
          value: `${item._id.value.sfid}`,
          label: `${item._id.value.accountName} (${item._id.value.sfid})`,
          group: `Account Name`,
        };
      } else if (item._id.type === "accountOwner") {
        return {
          ...item,
          key: `${item._id.value.accountOwner}`,
          value: `${item._id.value.accountOwner}`,
          label: `${item._id.value.accountOwner}`,
          group: `Account Executive`,
        };
      } else {
        return {
          ...item,
          key: `${item._id.value.ownerEmail}`,
          value: `${item._id.value.ownerFullName}`,
          label: `${item._id.value.ownerFullName} (${item._id.value.ownerEmail})`,
          group: `Solutions Architect`,
        };
      }
    });
  };

  return (
    <Autocomplete
      mt="md"
      transition="pop-top-left"
      transitionDuration={80}
      transitionTimingFunction="ease"
      value={searchTerm}
      itemComponent={AutoCompleteItem}
      onChange={(e) => {
        setSearchTerm(e);
        setAccountSelected({
          type: "ownerFullName",
          value: currentUser!.profile.name!,
        });
      }}
      data={searchResults()}
      onItemSubmit={(e) => {
        setAccountSelected({
          type: e["_id"]["type"],
          value: e.value,
        });
      }}
      rightSection={
        searchIsLoading || searchIsFetching ? (
          <Loader size={16} />
        ) : accountSelected ? (
          <IconCheck size={16} color="green" />
        ) : null
      }
      label="Search by Account Name, Solutions Architect, or Account Executive"
      placeholder="Acme Corp"
      classNames={classes}
      filter={(value, item) => true}
    />
  );
}
