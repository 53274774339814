import { FileWithPath } from "@mantine/dropzone";
import AWS, { S3 } from "aws-sdk";

const uploadFileToS3Bucket = async (file: FileWithPath, fileName: string) => {
  const {
    REACT_APP_ACCESS_KEY_ID,
    REACT_APP_SECRET_ACCESS_KEY,
    REACT_APP_S3_BUCKET,
  } = process.env;

  const S3_BUCKET = REACT_APP_S3_BUCKET!;
  const S3_REGION = "us-east-1";

  AWS.config.update({
    accessKeyId: REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  });

  // Create an instance of the S3 service
  const s3 = new S3({
    params: { Bucket: S3_BUCKET },
    region: S3_REGION,
  });

  // Set the parameters for the S3 upload
  const params = {
    Bucket: S3_BUCKET,
    Key: `uploads/${fileName}.json`, // Specify the folder name in the Key parameter
    Body: file,
  };

  // Upload the file to the S3 bucket
  return await s3.upload(params).promise();
};

export default uploadFileToS3Bucket;
