import {
  Stack,
  RingProgress,
  Text,
  Group,
  Box,
  LoadingOverlay,
  Button,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { sortBy } from "lodash";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTopCollections } from "realm/datahooks";
import { topCollections } from "realm/types";
import StatsProgressCompare from "ui/components/stats-bar-charts";
import { formatBytes } from "utils/format-bytes";

const PAGE_SIZE = 5;
const TABLE_LIMIT_TOP_N = 5;
const QUERY_LIMIT = 1000;

const StatComponentTopCollections = (props: {
  nCollections: number;
  totalCollectionSize: number;
}) => {
  const { nCollections, totalCollectionSize } = props;

  const params = useParams();

  const { isLoading, data: topCollectionsByDataSize } = useQuery({
    queryKey: ["sizingTopCollections", params.id],
    queryFn: () =>
      getTopCollections({
        sizingId: params.id!,
        numCollections: QUERY_LIMIT,
      }),
    staleTime: Infinity,
    useErrorBoundary: true,
    onSuccess: (data) => {
      setRecords(data.map((index, i) => ({ ...index, id: i })));
    },
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "dataSize",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState<Array<topCollections>>([]);

  useEffect(() => {
    const data = sortBy(records, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const PAGE_FROM = (page - 1) * PAGE_SIZE;
  const PAGE_TO = PAGE_FROM + PAGE_SIZE;

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  const totalTopCollectionSize = topCollectionsByDataSize!
    .slice(0, TABLE_LIMIT_TOP_N)
    .reduce((partialSum, a) => partialSum + a.dataSize, 0);
  const topNPercentage = (totalTopCollectionSize / totalCollectionSize) * 100;

  return (
    <>
      <Text align="center" weight={700} size="xl">
        {`Top ${QUERY_LIMIT} Collections`}
      </Text>
      <Group position="center" noWrap>
        <Box w={500}>
          <StatsProgressCompare
            unit="byte"
            fillPercent={100}
            data={topCollectionsByDataSize!
              .map((item) => {
                return {
                  label: `${item.dbName}.${item.collectionName}`,
                  value: item.dataSize,
                };
              })
              .slice(0, 5)}
          />
        </Box>
        <Stack spacing={0} align="center">
          <RingProgress
            size={150}
            thickness={25}
            label={
              <Text color="blue" weight={700} align="center" size="sm">
                {topNPercentage.toFixed(1)}%
              </Text>
            }
            sections={[
              {
                value: topNPercentage,
                color: "teal.6",
                tooltip: `Top Collections - ${formatBytes(
                  totalTopCollectionSize
                )}`,
              },
              {
                value: 100 - topNPercentage,
                color: "cyan.6",
                tooltip: `All Other Collections - ${formatBytes(
                  totalCollectionSize - totalTopCollectionSize
                )}`,
              },
            ]}
          />
          <Text>Collection Count: {nCollections}</Text>
          <Text>Total Size: {formatBytes(totalCollectionSize)}</Text>
        </Stack>
      </Group>

      <DataTable
        mt="sm"
        withBorder
        withColumnBorders
        striped
        minHeight={200}
        columns={[
          {
            accessor: "dbName",
            title: "Namespace",
            render: ({ dbName, collectionName }) => {
              return `${dbName}.${collectionName}`;
            },
            ellipsis: true,
          },
          {
            accessor: "dataSize",
            sortable: true,
            width: 110,
            textAlignment: "right",
            render: ({ dataSize }) => {
              return formatBytes(dataSize, 1);
            },
          },
          {
            accessor: "storageSize",
            sortable: true,
            width: 135,
            textAlignment: "right",
            render: ({ storageSize }) => {
              return formatBytes(storageSize, 1);
            },
          },
        ]}
        totalRecords={topCollectionsByDataSize!.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        records={records!.slice(PAGE_FROM, PAGE_TO)}
        idAccessor={"id"}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        noRecordsText="No Collection Information"
      />
      <Button
        mt="sm"
        onClick={() =>
          console.log("topCollectionsByDataSize", topCollectionsByDataSize)
        }
      >
        test
      </Button>
    </>
  );
};

export default StatComponentTopCollections;
