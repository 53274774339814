import {
  createStyles,
  Text,
  Autocomplete,
  Loader,
  SelectItemProps,
  MantineColor,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { forwardRef } from "react";
import { searchAccount } from "realm/datahooks";
import { Account } from "realm/types";
import { useSizingFormContext } from "../providers/new-sizing-context";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: "auto",
    paddingTop: 18,
    "&[data-success]": {
      border: `2px solid ${theme.colors.green[6]}`,
    },
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

interface ItemProps extends SelectItemProps, Account {
  color: MantineColor;
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ nm, owner, highlights, value, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text>
        {highlights.length > 0
          ? highlights[0].texts.map((highlight, index) => {
              return highlight.type === "hit" ? (
                <b key={highlight.type + index}>{highlight.value}</b>
              ) : (
                <span key={highlight.type + index}>{highlight.value}</span>
              );
            })
          : `${value}`}
      </Text>
      <Text size="xs" color="dimmed">
        {`${nm} - ${owner}`}
      </Text>
    </div>
  )
);

interface SearchAccountProps {
  accountSelected: string;
  setAccountSelected: React.Dispatch<React.SetStateAction<string>>;
}

export function SearchAccount(props: SearchAccountProps) {
  const { classes } = useStyles();

  const form = useSizingFormContext();

  const { accountSelected, setAccountSelected } = props;

  const [debounced] = useDebouncedValue(form.values.accountName, 500);

  const queryClient = useQueryClient();
  const {
    data: searchData,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
  } = useQuery({
    queryKey: ["searchAccount", debounced],
    queryFn: () => searchAccount(debounced),
    staleTime: Infinity,
    enabled: !accountSelected,
    keepPreviousData: true,
  });

  return (
    <Autocomplete
      data-success={accountSelected || undefined}
      mt="md"
      transition="pop-top-left"
      transitionDuration={80}
      transitionTimingFunction="ease"
      itemComponent={AutoCompleteItem}
      data={
        searchData
          ? searchData.map((item, i) => ({
              ...item,
              key: `${item._id}`,
              value: `${item.nm}`,
            }))
          : []
      }
      onItemSubmit={(e) => {
        form.setValues({ sfid: e._id, accountOwner: e.owner });
        setAccountSelected(e._id);

        queryClient.invalidateQueries({
          queryKey: ["clusterNames", e._id],
        });

        queryClient.invalidateQueries({
          queryKey: ["opportunities", e._id],
        });
      }}
      rightSection={
        searchIsLoading || searchIsFetching ? (
          <Loader size={16} />
        ) : accountSelected ? (
          <IconCheck size={16} color="green" />
        ) : null
      }
      label="Salesforce Account"
      placeholder="Acme Corp"
      classNames={classes}
      required
      filter={(value, item) => true}
      {...form.getInputProps("accountName")}
      onChange={(e) => {
        setAccountSelected("");
        form.getInputProps("accountName").onChange(e);
      }}
    />
  );
}
