import { Space, Stack, Text } from "@mantine/core";
import {
  IconBook,
  IconBrandMongodb,
  IconChecks,
  IconEngine,
  IconFingerprint,
  IconKey,
  IconLink,
  IconPencil,
  IconWood,
} from "@tabler/icons";
import { TalkingPoint, SizingData } from "realm/types";
import { StatsCard } from "../components/stats-card";
import {
  StatsDataTable,
  StatTableElementProps,
} from "../components/stats-data-table";
import { getTalkingPointIndex, showError } from "../utils/red-flag-utils";
import { determineNetworkCompressors } from "../utils/sizing-processors";

const StatComponentBinaryDetails = (props: {
  data: SizingData;
  talkingPoints: TalkingPoint[];
}) => {
  const { data, talkingPoints } = props;

  const versionSupportNearEOLIndex = getTalkingPointIndex(
    "versionSupportNearEOL",
    talkingPoints
  );
  const versionSupporEOLIndex = getTalkingPointIndex(
    "versionSupportEOL",
    talkingPoints
  );
  const versionSupportIndex = Math.max(
    versionSupportNearEOLIndex,
    versionSupporEOLIndex
  );
  const binaryIndex = getTalkingPointIndex("binary", talkingPoints);
  const storageEngineIndex = getTalkingPointIndex(
    "storageEngine",
    talkingPoints
  );
  const dbAuditingIndex = getTalkingPointIndex("dbAuditing", talkingPoints);

  const binaryElements: StatTableElementProps[] = [
    {
      icon: IconBrandMongodb,
      name: "MDB Version",
      value: data.serverInfo.version,
      error: showError(versionSupportIndex, talkingPoints),
      errorTitle: talkingPoints[versionSupportIndex]?.title,
      errorMessage: talkingPoints[versionSupportIndex]?.message,
    },
    {
      icon: IconChecks,
      name: "Edition",
      value: data.serverInfo.binary,
      error: showError(binaryIndex, talkingPoints),
      errorTitle: talkingPoints[binaryIndex]?.title,
      errorMessage: talkingPoints[binaryIndex]?.message,
    },
    {
      icon: IconEngine,
      name: "Storage Engine",
      value: data.serverInfo.storageEngine,
      error: showError(storageEngineIndex, talkingPoints),
      errorTitle: talkingPoints[storageEngineIndex]?.title,
      errorMessage: talkingPoints[storageEngineIndex]?.message,
    },
    {
      icon: IconWood,
      name: "DB Auditing",
      value: data.serverCmdLineOpts?.parsed.auditLog?.destination
        ? "Enabled"
        : "Disabled",
      error: showError(dbAuditingIndex, talkingPoints),
      errorTitle: talkingPoints[dbAuditingIndex]?.title,
      errorMessage: talkingPoints[dbAuditingIndex]?.message,
    },
  ];

  const dbAuthenticationIndex = getTalkingPointIndex(
    "dbAuthentication",
    talkingPoints
  );
  const networkEncryptionIndex = getTalkingPointIndex("tlsMode", talkingPoints);

  const defaultsElements: StatTableElementProps[] = [
    {
      icon: IconBook,
      name: "Read Concern",
      value: data.serverInfo.defaultReadConcern?.level || "N/A",
    },
    {
      icon: IconPencil,
      name: "Write Concern",
      value: data.serverInfo.defaultWriteConcern?.w || "N/A",
    },
    {
      icon: IconLink,
      name: "Net Compressor",
      value: determineNetworkCompressors(data.serverInfo.network).join(", "),
    },
    {
      icon: IconFingerprint,
      name: "Authentication",
      value: data.serverCmdLineOpts?.parsed.security?.authorization || "N/A",
      error: showError(dbAuthenticationIndex, talkingPoints),
      errorTitle: talkingPoints[dbAuthenticationIndex]?.title,
      errorMessage: talkingPoints[dbAuthenticationIndex]?.message,
    },
    {
      icon: IconKey,
      name: "TLS Mode",
      value: data.tlsMode || "N/A",
      error: showError(networkEncryptionIndex, talkingPoints),
      errorTitle: talkingPoints[networkEncryptionIndex]?.title,
      errorMessage: talkingPoints[networkEncryptionIndex]?.message,
    },
  ];

  const BinaryDetailsTooltip = () => {
    return (
      <Stack>
        <Text>
          <Text fw={700} inline>
            Versions:
          </Text>{" "}
          Must be at least 4.4 to migrate to Atlas.
        </Text>
        <Text>
          <Text fw={700} inline>
            Edition:
          </Text>{" "}
          If self managed and Enterprise, requires a license. May have Cloud
          Manager or Ops Manager.
        </Text>
        <Text>
          <Text fw={700} inline>
            Storage Engine:
          </Text>{" "}
          WiredTiger is the default. Encrypted Storage Engine is an uplift
          feature. MMAP is a red flag.
        </Text>
      </Stack>
    );
  };

  return (
    <StatsCard
      title={"Binary Details"}
      infoTooltipNode={<BinaryDetailsTooltip />}
    >
      <StatsDataTable title="Binary Specs" records={binaryElements} />
      <Space h="sm" />
      <StatsDataTable title="Defaults" records={defaultsElements} />
    </StatsCard>
  );
};

export default StatComponentBinaryDetails;
