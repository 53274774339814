import { Button, Stack, Title } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import uploadFileToS3Bucket from "utils/s3-upload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getCurrentUser } from "realm/authentication";
import { updateSizing, uploadSizing } from "realm/datahooks";
import { FileDropZone } from "ui";
import { SearchAccount } from "../components/search-account";
import SelectOpportunity from "../components/select-opportunity";
import SizingInstructions from "../components/sizing-instructions";
import {
  SizingFormProvider,
  useSizingForm,
  initial_sizing_values,
} from "../providers/new-sizing-context";
import UploadProgress from "../components/upload-progress";
// import useMongoChangeStream from "realm/useMongoChangeStream";

function NewSizingPage(props: { title: string }) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const currentUser = getCurrentUser();

  // const { startWatch } = useMongoChangeStream();

  const [accountSelected, setAccountSelected] = useState("");
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [sizingIdentifier, setSizingIdentifier] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync: mutateUploadSizing } = useMutation({
    mutationFn: uploadSizing,
  });

  const invalidateQueries = async () => {
    queryClient.invalidateQueries({
      queryKey: [
        "allSizings",
        {
          type: "ownerFullName",
          value: currentUser!.profile.name!,
        },
      ],
    });
  };

  const updateStatus = useMutation({ mutationFn: updateSizing });

  const uploadToS3 = async (fileName: string) => {
    uploadFileToS3Bucket(file!, fileName)
      .then((data) => {
        console.log("File Uploaded to S3: ", data);
        // console.log("Updating Sizing Status to 2: ", fileName);
        updateStatus.mutate({
          _id: fileName,
          payload: { "status.1": { s: 1, m: "File Uploaded Successfully" } },
        });
        return data;
      })
      .catch((error) => console.error("Error Uploading to S3: ", error));
  };

  const onErrorUpload = (error: unknown) => console.log("Error: ", error);

  const form = useSizingForm({
    initialValues: {
      ...initial_sizing_values,
      ownerEmail: currentUser!.profile.email || "",
      ownerFullName: currentUser!.profile.name || "",
    },

    validate: {
      accountName: (value) => (value ? null : "Cant be empty"),
      clusterName: (value) => (value[0] ? null : "Cant be empty"),
      opportunityName: (value) => (value[0] ? null : "Cant be empty"),
    },
  });

  return (
    <SizingFormProvider form={form}>
      <form
        onSubmit={form.onSubmit((values) => {
          setIsUploading(true);
          mutateUploadSizing({
            ...values,
            clusterName: values.clusterName,
          })
            .then(async (data) => {
              await invalidateQueries();
              setSizingIdentifier(data.toString());
              return data.toString();
            })
            // .then((data) => {
            //   startWatch(data);
            //   return data.toString();
            // })
            .then((data) => uploadToS3(data))
            .catch(onErrorUpload);
        })}
      >
        <Stack spacing={0}>
          <Title>Start a New Sizing</Title>
          <SizingInstructions />
        </Stack>
        <SearchAccount
          {...{
            accountSelected,
            setAccountSelected,
          }}
        />
        <SelectOpportunity {...{ accountSelected }} />

        <FileDropZone file={file} setFile={setFile} />

        <Button mt="md" type="submit" disabled={!form.isValid()}>
          {!form.isValid() ? "Fill Form to Upload" : "Click to upload!"}
        </Button>
      </form>
      <UploadProgress
        opened={isUploading}
        sizingIdentifier={sizingIdentifier}
      />
    </SizingFormProvider>
  );
}

export default NewSizingPage;
