import { DataTable } from "mantine-datatable";
import { SizingData } from "realm/types";
import formatNumber from "utils/format-number";

const StatComponentDocumentMetrics = (props: { data: SizingData }) => {
  const { data } = props;
  const { serverInfo } = data;
  const { metrics } = serverInfo;
  const { document } = metrics;

  const elements = [
    {
      label: `Returned`,
      value: document?.returned || 0,
    },
    {
      label: `Inserted`,
      value: document?.inserted || 0,
    },
    {
      label: `Updated`,
      value: document?.updated || 0,
    },
    {
      label: `Deleted`,
      value: document?.deleted || 0,
    },
  ].sort((a, b) => b.value - a.value);

  return (
    <DataTable
      withBorder
      striped
      columns={[
        { accessor: "label", title: "Documents" },
        {
          accessor: "value",
          title: "",
          textAlignment: "right",
          render: ({ value }) => {
            return formatNumber(value, 2);
          },
        },
      ]}
      records={elements}
      idAccessor="label"
    />
  );
};

export default StatComponentDocumentMetrics;
