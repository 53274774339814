import { AppShell, Container, Group } from "@mantine/core";
import { Outlet } from "react-router";
import { HeaderSearch } from "ui/components/header";
import { VersionInfo } from "ui/components/version-info";

function RootPage() {
  const links = [
    { link: "/new", label: "New Sizing" },
    { link: "/view", label: "Find Sizing" },
    { link: "/dashboard", label: "Usage Dashboard" },
    // { link: "/faq", label: "FAQ" }, // Commented out to remove FAQ link
  ];
  return (
    <AppShell header={<HeaderSearch links={links} />}>
      <Container size={1000}>
        <Outlet />
      </Container>
      <Group mt="xl" position="center">
        <VersionInfo />
      </Group>
    </AppShell>
  );
}

export default RootPage;
