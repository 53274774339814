import { getCurrentUser } from "realm/authentication";
import {
  SearchSizingDataTable,
  SearchSizingInput,
} from "../types/search-types";

// TODO: This function should be implemented in the datahooks file

export const getSizingsBySearchTerms = (
  props: SearchSizingInput
): Promise<SearchSizingDataTable[]> => {
  return getCurrentUser()!.functions.getSizingsBySearchTerms(props);
};
