import { SizingStatus } from "realm/types";

export const sizing_status: SizingStatus[] = [
  { s: 1, m: "Created New ID" }, // Step 0: Sizing ID Generated
  { s: 0, m: "Upload File to S3" }, // Step 1: File Uploaded to S3
  { s: 0, m: "Process Database, Collection, and Index Stats" }, // Step 2: Processed Database, Collection, and Index Stats
  { s: 0, m: "Upload Server Information" }, // Step 3: Uploaded Server Info
  { s: 0, m: "Upload Database Stats" }, // Step 4: Uploaded Database Stats
  { s: 0, m: "Upload Collection Stats" }, // Step 5: Uploaded Collection Stats
  { s: 0, m: "Upload Index Stats" }, // Step 6: Uploaded Index Stats
  { s: 0, m: "Generating Talking Points" }, // Step 7: Generated Talking Points
  { s: 0, m: "Generating Suggested Sizing" }, // Step 8: Generated Suggested Sizings
  { s: 0, m: "Sizing Completed " }, // Step 9: Sizing Complete
];
