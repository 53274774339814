import { SimpleGrid } from "@mantine/core";
import {
  SummaryClusterSizing,
  SummaryTalkingPoints,
} from "features/view-sizing-summary";
import { Sizing, SuggestedSizing, TalkingPoint } from "realm/types";
import { StatsCard } from "../components/stats-card";

const SizingSummaryTab = (props: {
  data: Sizing;
  talkingPoints: TalkingPoint[];
  suggestedSizing: SuggestedSizing | undefined;
}) => {
  const { data, talkingPoints, suggestedSizing } = props;

  return (
    <>
      <SimpleGrid>
        <StatsCard title={"Suggested Sizing"}>
          <SummaryClusterSizing suggestedSizing={suggestedSizing} />
        </StatsCard>
      </SimpleGrid>
      <SimpleGrid mt="md">
        <StatsCard title={"Points of Conversation"}>
          <SummaryTalkingPoints id={data._id!} flags={talkingPoints} />
        </StatsCard>
      </SimpleGrid>
    </>
  );
};

export default SizingSummaryTab;
