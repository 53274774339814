import {
  IconBraces,
  IconDeviceFloppy,
  IconMath1Divide3,
  IconSearch,
  IconZip,
} from "@tabler/icons";
import { SizingData } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import { StatsCard } from "../components/stats-card";
import {
  StatsDataTable,
  StatTableElementProps,
} from "../components/stats-data-table";

const StatComponentDataSizes = (props: { data: SizingData }) => {
  const { data } = props;

  const dataElements: StatTableElementProps[] = [
    {
      icon: IconBraces,
      name: "Data Size",
      value: formatBytes(data.totalDataSize),
    },
    {
      icon: IconDeviceFloppy,
      name: "Storage Size",
      value: formatBytes(data.totalStorageSize),
    },
    {
      icon: IconZip,
      name: "Compression Ratio",
      value: `${(data.totalDataSize / data.totalStorageSize).toFixed(2)}:1`,
    },
    {
      icon: IconSearch,
      name: "Index Size",
      value: formatBytes(data.totalIndexSize),
    },
    {
      icon: IconMath1Divide3,
      name: "Index:Data Ratio",
      value: `${(data.totalIndexSize / data.totalDataSize).toFixed(2)}:1`,
    },
  ];

  return (
    <StatsCard title={"Data Sizes"}>
      <StatsDataTable title={""} records={dataElements} />
    </StatsCard>
  );
};

export default StatComponentDataSizes;
