import { Group } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { MongoDBInstance, SuggestedSizingInput } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import formatNumber from "utils/format-number";

const SizingCapacityTable = (props: {
  selectedConfig: MongoDBInstance;
  selectedInputs: SuggestedSizingInput;
}) => {
  const { selectedConfig, selectedInputs } = props;

  const shardCount = selectedConfig?.shardConfig?.shardCount || 1;

  const elements = [
    {
      label: `RAM`,
      value: `${selectedConfig.ramGB * shardCount} GB`,
    },
    {
      label: `CPU`,
      value: `${selectedConfig.vCPU * shardCount} Cores`,
    },
    {
      label: `WiredTiger Cache`,
      value: formatBytes(
        selectedConfig.wiredTigerSizeMB * shardCount * 1000 ** 2
      ),
    },
    {
      label: `Storage`,
      value: formatBytes(
        selectedConfig.storageLimitGB * shardCount * 1000 ** 3 || 0
      ),
    },
    {
      label: `Disk Throughput`,
      value: `${formatNumber(selectedConfig.maxIOPS * shardCount, 0)} IOPS`,
    },
  ];

  const indexesPerSet =
    (selectedInputs.totalIndexSizeMB * 1000 ** 2) / shardCount;
  const diskUsedPerSet =
    (selectedInputs.disk.diskUtilizationMB * 1000 ** 2) / shardCount;
  const wtCachePerSet = selectedConfig.wiredTigerSizeMB * 1000 ** 2;
  const dataPerSet =
    (selectedInputs.data.totalDataSizeMB * 1000 ** 2) / shardCount;

  const setDistribution = [
    {
      label: `Indexes`,
      value: formatBytes(indexesPerSet, 0),
    },
    {
      label: `Disk Used`,
      value: formatBytes(diskUsedPerSet, 0),
    },
    {
      label: `FAD Cache`,
      value: formatBytes(Math.max(wtCachePerSet - indexesPerSet, 0), 0),
    },
    {
      label: `Data in WT`,
      value:
        (
          (Math.max(wtCachePerSet - indexesPerSet, 0) / dataPerSet) *
          100
        ).toFixed(0) + "%",
    },
    {
      label: `Index in WT`,
      value:
        (Math.min(wtCachePerSet / indexesPerSet, 1) * 100).toFixed(0) + "%",
    },
  ];

  return (
    <Group align="stretch" grow>
      <DataTable
        withBorder
        striped
        columns={[
          { accessor: "label", title: "Resource Capacity" },
          {
            accessor: "value",
            title: "",
            textAlignment: "right",
          },
        ]}
        records={elements}
        idAccessor="label"
      />

      <DataTable
        withBorder
        striped
        columns={[
          { accessor: "label", title: "Distributions" },
          {
            accessor: "value",
            title: "",
            textAlignment: "right",
          },
        ]}
        records={setDistribution}
        idAccessor="label"
      />
    </Group>
  );
};

export default SizingCapacityTable;
