import {
  Container,
  Group,
  Image,
  Paper,
  Title,
  Text,
  Stack,
} from "@mantine/core";
import { useEffect } from "react";
import { Navigate } from "react-router";
import { useRealmAuth } from "realm/auth.provider";
import { GoogleButton } from "../components/google-button";

function SignInPage(props: { title: string }) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const { isLoggedIn } = useRealmAuth();

  if (isLoggedIn) {
    return <Navigate to="/view" />;
  }

  return (
    <Container size={420} my={100}>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack align="center">
          <Group position="center" spacing={0} align="end">
            <Image src="./logo.jpeg" height={50} width={40} fit="contain" />
            <Title
              align="center"
              sx={(theme) => ({
                fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                fontWeight: 900,
              })}
            >
              mSizer
            </Title>
          </Group>
          <Text color="dimmed">A sizing tool for SCs, by SCs.</Text>

          <GoogleButton />
        </Stack>
      </Paper>
    </Container>
  );
}

export default SignInPage;
