import {
  Box,
  Group,
  LoadingOverlay,
  RingProgress,
  Stack,
  Text,
} from "@mantine/core";
import { Sizing } from "realm/types";
import StatsProgressCompare from "ui/components/stats-bar-charts";
import { formatBytes } from "utils/format-bytes";
import StatDatabaseDeepDive from "./database-deepdive";
import DownloadCSVButton from "../components/download-csv-button";
import {
  getCollectionCSVData,
  getTopDatabasesAndCollections,
} from "realm/datahooks";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const StatComponentTopDatabases = (props: { data: Sizing }) => {
  const { data } = props;
  const { data: originalSizingData } = data;
  const params = useParams();

  const TABLE_LIMIT_TOP_N = 5;
  const QUERY_LIMIT = 100;

  const {
    isLoading,
    // isError,
    data: topDatabasesAndCollections,
  } = useQuery({
    queryKey: ["sizingTopDatabasesAndCollections", params.id],
    queryFn: () =>
      getTopDatabasesAndCollections({
        sizingId: params.id!,
        numDatabases: QUERY_LIMIT,
      }),
    staleTime: Infinity,
    useErrorBoundary: true,
    // onSuccess: (data) => {
    //   console.log(data);
    // },
  });

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  const topNDbSize = topDatabasesAndCollections!
    .slice(0, TABLE_LIMIT_TOP_N)
    .reduce((partialSum, a) => partialSum + a.dataSize, 0);
  const topNPercentage = (topNDbSize / originalSizingData.totalDataSize) * 100;

  return (
    <>
      <Text align="center" weight={700} size="xl">
        {`Top ${QUERY_LIMIT} Databases`}
      </Text>
      <Group position="center" noWrap>
        <Box w={500}>
          <StatsProgressCompare
            unit="byte"
            fillPercent={100}
            data={topDatabasesAndCollections!
              .slice(0, TABLE_LIMIT_TOP_N)
              .map((item) => {
                return {
                  label: item.dbName!.toLocaleString(),
                  value: item.dataSize,
                };
              })}
          />
        </Box>
        <Stack spacing={0} align="center">
          <RingProgress
            size={150}
            thickness={25}
            label={
              <Text color="blue" weight={700} align="center" size="sm">
                {topNPercentage.toFixed(1)}%
              </Text>
            }
            sections={[
              {
                value: topNPercentage,
                color: "teal.6",
                tooltip: `Top Databases - ${formatBytes(topNDbSize)}`,
              },
              {
                value: 100 - topNPercentage,
                color: "cyan.6",
                tooltip: `All Other Databases - ${formatBytes(
                  originalSizingData.totalDataSize - topNDbSize
                )}`,
              },
            ]}
          />
          <Text>Database Count: {originalSizingData.nDatabases}</Text>
          <Text>
            Total Size: {formatBytes(originalSizingData.totalDataSize)}
          </Text>
        </Stack>
      </Group>

      <StatDatabaseDeepDive data={topDatabasesAndCollections!} />
      <Group position="right" mt="sm">
        <DownloadCSVButton
          datas={() => getCollectionCSVData(data._id!)}
          filename={`${data.accountName}_${data.clusterName}`} // Future: May want to use React Query to leverage caching, or process CSV locally.
        />
      </Group>
    </>
  );
};

export default StatComponentTopDatabases;
