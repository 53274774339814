import { useState } from "react";
import { Modal, Group, ActionIcon, Button, Text, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSizing } from "realm/datahooks";
import { useNavigate } from "react-router";

const DeleteSizingModal = (props: { id: string }) => {
  const [opened, setOpened] = useState(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteSizing,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["allSizings"] });
      navigate(`/view/`);
    },
    onError: (error) => console.log("Error: ", error),
  });
  const { mutate: mutateDeleteSizing } = mutation;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        withCloseButton={false}
        centered
      >
        <Text>Are you sure you want to delete the sizing?</Text>
        <Group position="right" mt="md">
          <Button variant="subtle" onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button onClick={() => mutateDeleteSizing(props.id)}>
            Delete Sizing
          </Button>
        </Group>
      </Modal>

      <Tooltip label="Delete" withArrow position="top" withinPortal>
        <ActionIcon variant="subtle" onClick={() => setOpened(true)}>
          <IconTrash color="red" size={18} />
        </ActionIcon>
      </Tooltip>
    </>
  );
};

export default DeleteSizingModal;
