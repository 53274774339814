import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useRealmAuth } from "realm/auth.provider";

import { ErrorPage, RootPage } from "ui";
import { AuthRedirectPage, SignInPage } from "features/authentication";
import { ViewSizingPage } from "features/view-sizing";
import { NewSizingPage } from "features/add-sizing";
import { SearchSizingPage } from "features/search-sizings";
import { UsageDashboard } from "features/usage-dashboard";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useRealmAuth();
  return isLoggedIn ? children : <Navigate to="/signin" />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <RootPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Navigate to="/view" /> },
      { path: "new", element: <NewSizingPage title="Sizing Tool" /> },
      { path: "view", element: <SearchSizingPage title="Sizing Tool" /> },
      { path: "view/:id", element: <ViewSizingPage /> },
      {
        path: "dashboard",
        element: <UsageDashboard title="Usage Dashboard" />,
      },
      // { path: "faq", element: <AboutPage title="Sizing Tool" /> },
    ],
  },
  {
    path: "signin",
    element: <SignInPage title="Sizing Tool" />,
  },
  {
    path: "redirect",
    element: <AuthRedirectPage />,
  },
]);

const PageRouter = () => {
  return <RouterProvider router={router} />;
};

export default PageRouter;
