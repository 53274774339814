import { TalkingPoint } from "realm/types";

const getTalkingPointIndex = (key: string, talkingPoints: TalkingPoint[]) => {
  return talkingPoints.findIndex((flag) => flag.id === key);
};

const showError = (index: number, talkingPoints: TalkingPoint[]): boolean => {
  return index > -1 && !talkingPoints[index].dismissed;
};

const buildError = (key: string, talkingPoints: TalkingPoint[]) => {
  const index = getTalkingPointIndex(key, talkingPoints);

  return {
    error: showError(index, talkingPoints),
    errorTitle: talkingPoints[index]?.title,
    errorMessage: talkingPoints[index]?.message,
  };
};

export { getTalkingPointIndex, showError, buildError };
