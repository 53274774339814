import { SizingData, SizingReplicaSetConfig } from "realm/types";

export const determineNetworkCompressors = (
  data: SizingData["serverInfo"]["network"]
) => {
  const compressors = data.compression;

  let result: string[] = [];

  let k: keyof SizingData["serverInfo"]["network"]["compression"];
  for (k in compressors) {
    const v = compressors[k];
    if (Number(v.compressor.bytesIn) > 0) {
      result.push(k);
    }
  }

  return result.length > 0 ? result : ["None"];
};

type NodeType =
  | "Electable"
  | "Arbiter"
  | "Hidden"
  | "Delayed"
  | "Primary"
  | "Secondary";

const nodeSortOrder: NodeType[] = [
  "Primary",
  "Secondary",
  "Arbiter",
  "Hidden",
  "Delayed",
];

export const determineNodeType = (data: SizingReplicaSetConfig): NodeType => {
  if (data.arbiterOnly) return "Arbiter";
  if (data.hidden) return "Hidden";
  if (data.secondaryDelaySecs > 0) return "Delayed";

  return "Electable";
};

export interface DeterminedNodeType {
  _id: number;
  sort: number;
  host: string;
  short: string;
  long: NodeType;
  tags: any;
  priority: number;
  votes: number;
}

export const determineNodeTypes = (
  data?: SizingReplicaSetConfig[]
): DeterminedNodeType[] => {
  if (!data) {
    return [
      {
        _id: 0,
        host: "",
        sort: nodeSortOrder.indexOf("Primary"),
        short: "P",
        long: "Primary",
        tags: {},
        priority: 1,
        votes: 1,
      },
    ];
  }

  let foundPrimary = false;

  const identifiedNodes: DeterminedNodeType[] = data.map((node) => {
    const nodeType = determineNodeType(node);
    const isElectable = nodeType === "Electable";

    if (isElectable && !foundPrimary) {
      foundPrimary = isElectable;

      return {
        _id: node._id,
        host: node.host,
        sort: nodeSortOrder.indexOf("Primary"),
        short: "P",
        long: "Primary",
        tags: node.tags,
        priority: node.priority,
        votes: node.votes,
      };
    }

    return {
      _id: node._id,
      host: node.host,
      sort: nodeSortOrder.indexOf(isElectable ? "Secondary" : nodeType),
      short: isElectable ? "S" : nodeType[0],
      long: isElectable ? "Secondary" : nodeType,
      tags: node.tags,
      priority: node.priority,
      votes: node.votes,
    };
  });

  return identifiedNodes.sort((a, b) => a.sort - b.sort);
};
